import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';

function PaperComponent(props: PaperProps) {
  return (
    <Paper {...props} />
  );
}

interface Props {
  open: boolean;
  title: string;
  content: string;
  cancel?: string;
  confirm?: string;
  confirmColor?: 'error';
  onCancel: () => void;
  onConfirm: () => void;
}

export default function DraggableDialog(props: Props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onCancel}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.onCancel} color="primary">
            {props.cancel || 'Cancel'}
          </Button>
          <Button onClick={props.onConfirm} variant="contained" color={props.confirmColor || "primary"}>
            {props.confirm || 'Conform'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
