import {
  createProductAPI, getProductsAPI, updateProductAPI,
  createMarchantProductAPI,
  updateMarchantProductAPI, getCategoriesAPI,
  getBrandsAPI, uploadProductImagesAPI, deleteMarchantProductAPI,
  getQuantitiesAPI, getPincodeLocationAPI, getLocationStoresAPI,
  getStoreProductsAPI, getStoreProductAPI, getBaseProductAPI, clearStaticConfig, getSearchProductsAPI, getMerchantTotalStockPriceAPI
} from '../backend/backend';
import { getQuantitiesMap } from '../helpers/product.helpers';
import { LocationStore, Product, ProductFilters } from '../types/product.types';
import { ProductsState } from '../types/store.types';
import { AreaLocation } from '../types/user.types';
import { handleAPIError, setProductsState, showSnackbar } from './store.service';

export const productsState: ProductsState = ({
  buyProducts: [],
  brands: [],
  categories: [],
  quantities: [],
  quantitiesMap: {},
  pincode: { code: '', location: {} },
  productFilters: { categories: [], brands: [], sortBy: 'name' },
  setBuyProducts: (buyProducts) => setProductsState({ buyProducts }),
  setPincode: (pincode) => setProductsState({ pincode }),
  setProductFilters: (productFilters) => setProductsState({ productFilters }),
  getBrands: () => getBrands(),
  getCategories: () => getCategories(),
  getQuantities: () => getQuantities(),
  getProducts: (filters) => getProducts(filters),
  getSearchProducts: (filters) => getSearchProducts(filters),
  createProduct: (product: Product) => createProduct(product),
  updateProduct: (product: Product) => updateProduct(product),
  createMarchantProduct: (product: Product) => createMarchantProduct(product),
  updateMarchantProduct: (product: Product) => updateMarchantProduct(product),
  deleteMarchantProduct: (productId: number) => deleteMarchantProduct(productId),
  getPincodeLocation: (pincode) => getPincodeLocation(pincode),
  getLocationStores: (pincode, isSubString) => getLocationStores(pincode, isSubString),
  getStoreProducts: (storeId) => getStoreProducts(storeId),
  getStoreProduct: (storeId, productId) => getStoreProduct(storeId, productId),
  getBaseProduct: (productId) => getBaseProduct(productId),
  getMerchantTotalStockPrice: () => getMerchantTotalStockPrice(),
});

const getBrands = async () => {
  try {
    const brands = await getBrandsAPI();
    if (brands) {
      setProductsState({ brands });
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get brands error" });
  }
};

const getCategories = async () => {
  try {
    const categories = await getCategoriesAPI();
    if (categories) {
      setProductsState({ categories });
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get categories error " });
  }
};

const getQuantities = async () => {
  try {
    const quantities = await getQuantitiesAPI();
    if (quantities) {
      const quantitiesMap = getQuantitiesMap(quantities);
      setProductsState({ quantities, quantitiesMap });
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get quantities error " });
  }
};

const getProducts = async (filters: ProductFilters) => {
  try {
    const response = await getProductsAPI(filters);
    if (response) {
      return response.data;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get products error " });
    return [];
  }
};

const getSearchProducts = async (filters: ProductFilters) => {
  try {
    const response = await getSearchProductsAPI(filters);
    if (response) {
      return response.data;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get products error " });
    return [];
  }
};

const createProduct = async (productInput: Product) => {
  let isSucceed = false;
  try {
    const product = { ...productInput };
    const formData = new FormData();
    const imageIndexes: number[] = [];
    product.images.forEach((image, i) => {
      if (image.file) {
        formData.append('images', (image.file as any));
        imageIndexes.push(i);
        image.url = undefined as any;
        image.file = undefined as any;
      }
    });
    const response = await createProductAPI(product);
    if (response) {
      isSucceed = true;
      product.id = response.data;

      clearStaticConfig();
    }
    if (imageIndexes.length > 0) {
      formData.append('id', `${product.id}`);
      const imageResponse = await uploadProductImagesAPI(formData);
      if (imageResponse) {
        for (let i = 0; i < (imageResponse.data || []).length; i++) {
          product.images[imageIndexes[i]].url = imageResponse.data[i];
        }
        await updateProductAPI(product);
        clearStaticConfig();
      }
    }
    if (response?.message) {
      showSnackbar(response.message);
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Create product error " });
  } finally {
    return isSucceed;
  }
};

const updateProduct = async (productInput: Product) => {
  let isSucceed = false;
  try {
    const product = { ...productInput };
    product.vPrice = undefined as any;
    const formData = new FormData();
    const imageIndexes: number[] = [];
    product.images.forEach((image, i) => {
      if (image.file) {
        formData.append('images', (image.file as any));
        imageIndexes.push(i);
        image.url = undefined as any;
        image.file = undefined as any;
      }
    });
    if (imageIndexes.length > 0) {
      formData.append('id', `${product.id}`);
      const response = await uploadProductImagesAPI(formData);
      if (response) {
        for (let i = 0; i < (response.data || []).length; i++) {
          product.images[imageIndexes[i]].url = response.data[i];
        }
      }
    }
    const response = await updateProductAPI(product);
    if (response) {
      isSucceed = true;
      showSnackbar(response.message);
      clearStaticConfig();
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Update product error " });
  } finally {
    return isSucceed;
  }
};

// TODO delete if not required
// const getMarchantProducts = async () => {
//   try {
//     const response = await getMarchantProductsAPI();
//     if (response && response.data) {
//       const marchantProducts = response.data;
//       setProductsState({ marchantProducts });
//     }
//   } catch (error: any) {
//     handleAPIError({ error, message: "Get marchant products error " });
//   }
// };

// TODO delete if not required
// const getAllMarchantProducts = async () => {
//   try {
//     const response = await getAllMarchantProductsAPI();
//     if (response && response.data) {
//       const allMarchantProducts = response.data;
//       setProductsState({ allMarchantProducts });
//     }
//   } catch (error: any) {
//     handleAPIError({ error, message: "Get all marchant products error " });
//   }
// };

const createMarchantProduct = async (marchantProduct: Product) => {
  let isSucceed = false;
  try {
    const response = await createMarchantProductAPI(marchantProduct);
    if (response) {
      isSucceed = true;
      // marchantProduct.id = response.data;
      // setProductsState((state) => ({ marchantProducts: [...state.marchantProducts, marchantProduct] }));
      showSnackbar(response.message);
      clearStaticConfig();
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Create marchant product error " });
  } finally {
    return isSucceed;
  }
};

const updateMarchantProduct = async (marchantProduct: Product) => {
  let isSucceed = false;
  try {
    const response = await updateMarchantProductAPI(marchantProduct);
    if (response) {
      isSucceed = true;
      // setProductsState((state) => ({ marchantProducts: state.marchantProducts.map((p) => p.id === marchantProduct.id ? marchantProduct : p) }));
      showSnackbar(response.message);
      clearStaticConfig();
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Update marchant product error " });
  } finally {
    return isSucceed;
  }
};

const deleteMarchantProduct = async (productId: number) => {
  let isSucceed = false;
  try {
    const response = await deleteMarchantProductAPI(productId);
    if (response) {
      isSucceed = true;
      // setProductsState((state) => ({ marchantProducts: state.marchantProducts.filter((p) => p.id !== productId) }));
      showSnackbar(response.message);
      clearStaticConfig();
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Delete marchant product error " });
  } finally {
    return isSucceed;
  }
};

const getPincodeLocation = async (pincode: string): Promise<AreaLocation> => {
  try {
    const response = await getPincodeLocationAPI(pincode);
    if (response) {
      return response;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Invalid Pincode!" });
  }
  return {} as AreaLocation;
};

const getLocationStores = async (pincode: string, isSubString = false): Promise<LocationStore[]> => {
  try {
    const response = await getLocationStoresAPI(pincode, isSubString);
    if (response) {
      return response;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get Location Stores Error ", showSnackbar: false });
  }
  return [];
};

const getStoreProducts = async (storeId: string): Promise<Product[]> => {
  try {
    const response = await getStoreProductsAPI(storeId);
    if (response) {
      return response || [];
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get Stores Products Error ", showSnackbar: false });
  }
  return [];
};

const getStoreProduct = async (storeId: string, productId: number): Promise<Product> => {
  try {
    const response = await getStoreProductAPI(storeId, productId);
    if (response) {
      return response;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get Stores Product Error " });
  }
  return null as any;
};

const getBaseProduct = async (productId: number): Promise<Product> => {
  try {
    const response = await getBaseProductAPI(productId);
    if (response) {
      return response;
    }
  } catch (error: any) {
    handleAPIError({ error, message: "Get Base Product Error " });
  }
  return null as any;
};

const getMerchantTotalStockPrice = async (): Promise<number> => {
  try {
    const response = await getMerchantTotalStockPriceAPI();
    return response?.data || 0;
  } catch (error: any) {
    handleAPIError({ error, message: "Get Marchant total stock Error " });
  }
  return 0;
};