import { updateOrderStatusAPI, getMyOrdersAPI, placeOrderAPI, getMarchantOrdersAPI, getMyOrderAPI, getCashfreeOrderStatusAPI, uploadPaymentProofAPI, getOrdersForMarchantDashboardAPI, getOrdersForUserDashboardAPI, getOrdersInvoiceAPI, sendOrdersInvoiceAPI } from '../backend/backend';
import { initPayment, initPaymentCashFree } from '../helpers/payment.helpers';
import { DashboardFilter } from '../types/dashboard.types';
import { MessageType, Order, OrderFilterType, OrderInvoice, OrderTracking } from '../types/product.types';
import { OrdersState } from '../types/store.types';
import { handleAPIError, setOrderState, showSnackbar } from './store.service';
import FileSaver from 'file-saver';

export const ordersState: OrdersState = ({
    cartItems: [],
    showPaymentDialog: false,
    isLoadingInvoice: false,
    addItemToCart: (productId, marchantId) => addItemToCart(productId, marchantId),
    removeItemFromCart: (productId) => removeItemFromCart(productId),
    placeOrder: (order, paymentProof) => placeOrder(order, paymentProof),
    isLoadingMyOrders: false,
    getMyOrders: (type) => getMyOrders(type),
    getMyOrder: (orderId: string) => getMyOrder(orderId),
    isLoadingMarchantOrders: false,
    getMarchantOrders: (type) => getMarchantOrders(type),
    updateOrderStatus: (orderId, status) => updateOrderStatus(orderId, status),
    getOrdersForMarchantDashboard: (filters) => getOrdersForMarchantDashboard(filters),
    getOrdersForUserDashboard: (filters) => getOrdersForUserDashboard(filters),
    getOrderInvoice: (invoice) => getOrderInvoice(invoice),
    sendOrderInvoice: (invoice, type) => sendOrderInvoice(invoice, type),
});

const addItemToCart = async (productId: number, marchantId: string) => {
    setOrderState((state) => {
        const cartItems = [...state.cartItems];
        const index = cartItems.findIndex(item => item.productId === productId);
        if (index > -1) {
            cartItems[index].quantity++;
        } else {
            cartItems.push({ productId, marchantId, quantity: 1 });
            showSnackbar('Product added to cart!');
        }
        return { ...state, cartItems };
    });
};

const removeItemFromCart = async (productId: number) => {
    setOrderState((state) => {
        let cartItems = [...state.cartItems];
        const index = cartItems.findIndex(item => item.productId === productId);
        if (index > -1) {
            cartItems[index].quantity--;
            if (cartItems[index].quantity <= 0) {
                cartItems = cartItems.filter(item => item.productId !== productId);
            }
        }
        return { ...state, cartItems };
    });
};

const uploadPaymentProof = async (file: File) => {
    if (!file) {
        return '';
    }
    try {
        const formData = new FormData();
        formData.append('paymentProof', file);
        const response = await uploadPaymentProofAPI(formData);
        if (response && response.data) {
            const imageURLS: { [key in string]: string } = response.data;
            return imageURLS.paymentProof;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Upload payment proof error " });
    }
    return '';
};

const placeOrder = async (order: Order, file: File) => {
    let orderId = null;
    try {
        const paymentProof = await uploadPaymentProof(file);
        order.orderDetails.payment.paymentDetails = { paymentProof };
        const response = await placeOrderAPI(order);
        if (response && response.data) {
            if (response.data.status === 'ORDERED' || response.data.status === 'DELIVERED') {
                orderId = response.data.orderId;
                setOrderState((state) => {
                    const productIds = order.orderDetails.products.map(product => product.id);
                    let cartItems = [...(state.cartItems.filter(item => productIds.indexOf(item.productId) === -1))];
                    return { cartItems };
                });
                showSnackbar(response.message);
            } else if (response.data.txToken) {
                showSnackbar(response.message, 'info');
                let error = null;
                if (response.data.status === 'PAY_TO_VSTEP') {
                    error = await initPayment(response.data);
                } else if (response.data.status === 'PAY_TO_CASHFREE') {
                    setOrderState({ showPaymentDialog: true });
                    error = await initPaymentCashFree(response.data);
                    if (!error) {
                        await getCashfreeOrderStatusAPI(response.data.orderId);
                    } else {
                        setOrderState({ showPaymentDialog: false });
                    }
                }
                if (error) {
                    showSnackbar(error, 'error');
                }
            }

        }
    } catch (error: any) {
        handleAPIError({ error, message: "Place order error " });
    }
    return orderId;
};

const getMyOrders = async (type: OrderFilterType) => {
    try {
        setOrderState({ isLoadingMyOrders: true });
        const response = await getMyOrdersAPI<{ data: Order[]; }>(type);
        if (response && response.data) {
            setOrderState({ isLoadingMyOrders: false });
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my orders error " });
    } finally {
        setOrderState({ isLoadingMyOrders: false });
    }
    return [];
};

const getMyOrder = async (orderId: string) => {
    try {
        const response = await getMyOrderAPI(orderId);
        if (response && response.data) {
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my order error " });
    }
};

const getMarchantOrders = async (type: OrderFilterType): Promise<Order[]> => {
    try {
        setOrderState({ isLoadingMarchantOrders: true });
        const response = await getMarchantOrdersAPI(type);
        if (response && response.data) {
            setOrderState({ isLoadingMarchantOrders: false });
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my orders error " });
    } finally {
        setOrderState({ isLoadingMarchantOrders: false });
    }
    return [];
};

const getOrdersForMarchantDashboard = async (filters: DashboardFilter): Promise<Order[]> => {
    try {
        const response = await getOrdersForMarchantDashboardAPI(filters);
        if (response && response.data) {
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get marchant dashboard orders error " });
    }
    return [];
};

const getOrdersForUserDashboard = async (filters: DashboardFilter): Promise<Order[]> => {
    try {
        const response = await getOrdersForUserDashboardAPI(filters);
        if (response && response.data) {
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get user dashboard orders error " });
    }
    return [];
};

const getOrderInvoice = async (invoice: OrderInvoice): Promise<void> => {
    try {
        setOrderState({ isLoadingInvoice: true });
        const response = await getOrdersInvoiceAPI(invoice);
        var blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `${invoice.order.invoiceId}.pdf`);
    } catch (error: any) {
        handleAPIError({ error, message: "Get order invoice error " });
    } finally {
        setOrderState({ isLoadingInvoice: false });
    }
};

const sendOrderInvoice = async (invoice: OrderInvoice, type: MessageType): Promise<void> => {
    try {
        setOrderState({ isLoadingInvoice: true });
        const response = await sendOrdersInvoiceAPI(invoice, type);
        if (response.message) {
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Send order invoice error", showSnackbar: true });
    } finally {
        setOrderState({ isLoadingInvoice: false });
    }
};

const updateOrderStatus = async (orderId: number, status: OrderTracking): Promise<boolean> => {
    try {
        const response = await updateOrderStatusAPI(orderId, status);
        if (response && response.data) {
            return true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Order status error " });
    }
    return false;
};
