import React, { Suspense, lazy } from "react";
import { styled } from '@mui/material/styles';
import {
  StyledEngineProvider,
  CssBaseline,
  Fab,
} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import theme from "./theme";
import GlobalStylesComponent from "./GlobalStyles";
import Pace from "./components/shared/Pace";
import AuthRoute from "./components/router/auth.router";
import { ROUTES } from "./types/routes";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useStore } from "./services/store.service";
import { Notifications } from "./notifications";
import CardLoader from "./components/shared/card-loader";
import { Alert } from "./components/shared/snakckbar";

const PREFIX = 'App';

const classes = {
  fab: `${PREFIX}-fab`,
  update: `${PREFIX}-update`,
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.fab}`]: {
    '&:hover': {
      backgroundColor: '#128C7E',
    },
    backgroundColor: '#25D366',
    color: '#fff',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 200
  },
  [`& .${classes.update}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    width: 'fit-content',
    alignItems: 'flex-end',
    display: 'none',
    cursor: 'pointer'
  }
}));

const Home = lazy(() => import("./components/home/home"));

const LoginRoute = lazy(() => import("./components/login/login.routing"));



function Component() {

  const { isLoaded, getUser } = useStore(state => state.user);
  if (!isLoaded) {
    getUser();
  }

  const history = useHistory();
  const gotoSupport = () => {
    history.push(ROUTES.home.subRoutes.support.route);
  };

  history.listen(() => {
    window.scrollTo(0, 0);
  });

  return (
    <StyledDiv>
      <CssBaseline />
      <Pace color={theme.palette.primary.light} />
      {
        isLoaded ?
          <Suspense fallback={<CardLoader />}>
            <Switch>
              <AuthRoute exact path={ROUTES.login.route} component={LoginRoute} />
              <AuthRoute exact path={ROUTES.signup.route} component={LoginRoute} />
              <AuthRoute exact path={ROUTES.forgotPassword.route} component={LoginRoute} />
              <Route path={ROUTES.home.route} component={Home} />
            </Switch>
          </Suspense>
          :
          <CardLoader />
      }

      <Fab aria-label="whatsapp" className={classes.fab} onClick={gotoSupport}>
        <WhatsAppIcon />
      </Fab>
      <Notifications />
      <Alert id='version-update-notification' className={classes.update} severity="info" onClick={() => window.location.reload()}>Tap here to update new version!</Alert>
    </StyledDiv>
  );
}

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStylesComponent theme={theme} />
          <Component />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
