import { getDistrictsAPI, getStatesAPI, getVillagesAPI } from '../backend/backend';
import { AddressState } from '../types/store.types';
import { Village } from '../types/user.types';
import { handleAPIError } from './store.service';

export const addressState: AddressState = ({
    getStates: () => getStates(),
    getDistricts: (state) => getDistricts(state),
    getVillages: (state, district) => getVillages(state, district)
});

const getStates = async () => {
    try {
        const response = await getStatesAPI();
        if (response) {
            return (response || []).sort();
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get States Error " });
    }
    return [];
};

const getDistricts = async (state: string) => {
    try {
        const response = await getDistrictsAPI(state);
        if (response) {
            return (response || []).sort();
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get Districts Error " });
    }
    return [];
};

const getVillages = async (state: string, district: string) => {
    try {
        const response = await getVillagesAPI(state, district);
        if (response) {
            return (response || []).sort((a: Village, b: Village) => {
                if (a.village < b.village) {
                    return -1;
                }
                if (a.village > b.village) {
                    return 1;
                }
                return 0;
            });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get Villages Error " });
    }
    return [];
};
