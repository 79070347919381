import { addPartnerAPI, getMyPartnersAPI, getMyReferralsAPI } from '../backend/backend';
import { AddPartnerType } from '../components/home/my-business/business.types';
import { BusinessState } from '../types/store.types';
import { handleAPIError, showSnackbar } from './store.service';

export const businessState: BusinessState = ({
    addPartner: (partner: AddPartnerType) => addPartner(partner),
    getMyPartners: () => getMyPartners(),
    getMyReferrals: () => getMyReferrals()
});

const addPartner = async (partner: AddPartnerType) => {
    let isSucceed = false;
    try {
        const response = await addPartnerAPI(partner);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Add partner error " });
    } finally {
        return isSucceed;
    }
};

const getMyPartners = async () => {
    let partners = [];
    try {
        const response = await getMyPartnersAPI();
        if (response) {
            partners = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my partners error " });
    } finally {
        return partners;
    }
};

const getMyReferrals = async () => {
    let partners = [];
    try {
        const response = await getMyReferralsAPI();
        if (response) {
            partners = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my referrals error " });
    } finally {
        return partners;
    }
};
