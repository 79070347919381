import { isDevMode } from "../helpers/config.helpers";

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const STATIC_PATH = isDevMode ? 'https://static.development.vstep.in' : `${window.location.protocol}//static.${window.location.host}`;

export const API_PATH = window.location.host === 'vstep.in' ? 'https://vstep-prod.herokuapp.com' :
    (isDevMode ? 'http://localhost:8080' : 'https://vstep-development.herokuapp.com');

export const NOTIFICATION_TOKEN_KEY = 'notification_token';
