import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackBarState, SnackBarType } from '../../types/store.types';
import { setSnackBarState, useStore } from '../../services/store.service';
import { TransitionProps } from '@mui/material/transitions';
import { Slide } from '@mui/material';

const PREFIX = 'Alert';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    cursor: 'pointer',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} sx={{ width: '100%' }} style={{ color: props.severity === 'warning' ? 'black' : '' }} variant="filled" {...props} />;
});

export const snackBarState: SnackBarState = ({
  isOpen: false,
  message: '',
  type: 'success',
  setIsOpen: (isOpen: boolean) => setSnackBarState({ isOpen }),
  showSnackbar: (message: string | JSX.Element, type?: SnackBarType, onClick?: () => void) =>
    setSnackBarState({ message, isOpen: true, type: type ? type : 'success', onClick }),
});

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export default function SnackBar() {

  const { isOpen, message, type, setIsOpen, onClick } = useStore(state => state.snackBar);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <Root className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={4000} onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={TransitionUp}
        onClick={onClick}>
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </Root>
  );
}
