import { Theme } from "@mui/material/styles";
import GlobalStyles from '@mui/material/GlobalStyles';

const styles = (theme: Theme) => ({
  "html": {
    height: "100%"
  },
  "body": {
    height: "100%"
  },
  "#root": {
    height: "100%"
  },
  /**
   * Disable the focus outline, which is default on some browsers like
   * chrome when focusing elements
   */
  "*:focus": {
    outline: 0
  },
  ".text-white": {
    color: theme.palette.common.white
  },
  ".listItemLeftPadding": {
    paddingTop: `${theme.spacing(1.75)} !important`,
    paddingBottom: `${theme.spacing(1.75)} !important`,
    paddingLeft: `${theme.spacing(4)} !important`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(4)} !important`
    },
    "@media (max-width:  420px)": {
      paddingLeft: `${theme.spacing(1)} !important`
    }
  },
  ".container": {
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
      maxWidth: 540
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      maxWidth: 720
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4),
      maxWidth: 1170
    },
  },
  ".row": {
    display: "flex",
    flexWrap: "wrap",
    marginRight: -theme.spacing(2),
    marginLeft: -theme.spacing(2)
  },
  ".container-fluid": {
    width: "100%",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 1370
  },
  ".lg-mg-top": {
    marginTop: `${theme.spacing(20)} !important`,
    [theme.breakpoints.down('lg')]: {
      marginTop: `${theme.spacing(18)} !important`
    },
    [theme.breakpoints.down('md')]: {
      marginTop: `${theme.spacing(16)} !important`
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing(14)} !important`
    }
  },
  ".lg-mg-bottom": {
    marginBottom: `${theme.spacing(20)} !important`,
    [theme.breakpoints.down('lg')]: {
      marginBottom: `${theme.spacing(18)} !important`
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: `${theme.spacing(16)} !important`
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.spacing(14)} !important`
    }
  },
  ".lg-p-top": {
    paddingTop: `${theme.spacing(20)} !important`,
    // [theme.breakpoints.down('lg')]: {
    //   paddingTop: `${theme.spacing(18)} !important`
    // },
    // [theme.breakpoints.down('md')]: {
    //   paddingTop: `${theme.spacing(16)} !important`
    // },
    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: `${theme.spacing(14)} !important`
    // }
  },
  ".pace": {
    pointerEvents: "none",
    userSelect: "none"
  },
  ".pace-inactive": {
    display: "none"
  },
  ".pace .pace-progress": {
    background: theme.palette.primary.light,
    position: "fixed",
    zIndex: 2000,
    top: 0,
    right: "100%",
    width: "100%",
    height: 3
  },
  "label.MuiTypography-root": {
    fontWeight: 500
  },
  ".align-center": {
    display: 'flex',
    justifyContent: 'center'
  }
});

const GlobalStylesComponent = (props: { theme: Theme; }) => {
  return (<GlobalStyles styles={{ ...styles(props.theme) as any }} />);
};

export default GlobalStylesComponent;

// transform-origin: 50% 50%;
// animation: 2.43902s linear 0s infinite normal forwards running flip-h;

// "@keyframes flip-h": {
//   "0%": {
//     animation-timing-function: cubic-bezier(0.1909,0.4373,0.4509,0.7454);
//     transform: rotateY(0deg);
//   },
//   "30% "{
//     animation-timing-function: cubic-bezier(0.128,0.2315,0.9704,0.8632);
//     transform: rotateY(153.72deg);
// }
//   "50%": {
//     animation-timing-function: cubic-bezier(0.5788,0.3001,0.5613,0.6784);
//     transform: rotateY(180deg);
//   }
// "55%": {
//   animation-timing-function: cubic-bezier(0.1545,0.4929,0.6089,0.9373);
//   transform: rotateY(238.68deg);
//   }, "100%" {
//     transform: rotateY(360deg);
//   }
// }