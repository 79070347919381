import { green, red, yellow } from "@mui/material/colors";
import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";

// colors
const primary = "#123733";
const secondary = "#123733";
const black = "#05110f";
const darkBlack = "rgb(36, 40, 44)";
const background = "#f5f5f5";
const light = "#f5f5f5";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme: Theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: primary },
    secondary: { main: secondary },
    light: { main: light },
    common: {
      black,
    },
    warning: {
      light: yellow[500],
      main: yellow[700],
      dark: yellow[900]
    },
    error: {
      light: red[300],
      main: red[400],
      dark: red[500]
    },
    success: {
      light: green[400],
      main: green[600],
      dark: green[800]
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background
    },
    // spacing
  } as any,
  typography: {
    fontSize: 14,
    fontWeightBold: 600,
    fontWeightMedium: 500,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing
          }
        }
      }
    },
    // MuiExpansionPanel: {
    //   styleOverrides: {
    //     root: {
    //       position: "static"
    //     }
    //   }
    // },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: darkBlack
        }
      }
    },
    // MuiExpansionPanelDetails: {
    //   root: {
    //     [`@media (max-width:  ${sm}px)`]: {
    //       paddingLeft: spacing,
    //       paddingRight: spacing
    //     }
    //   }
    // }
    MuiCard: {
      defaultProps: {
        elevation: 3
      }
    }
  }
});

export default responsiveFontSizes(theme);
