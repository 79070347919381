import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const PREFIX = 'card-loader';

const classes = {
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  loading: `${PREFIX}-loading`,
  image: `${PREFIX}-image`,
  cardContainer: `${PREFIX}-cardContainer`,
};

const StyledCard = styled('div')(({ theme }) => ({
  [`&.${classes.card}`]: {
    pointerEvents: 'none',
    position: 'fixed',
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 90,
    backgroundColor: '#ffffffc2'
  },
  [`& .${classes.cardContainer}`]: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 100
  },
  [`& .${classes.media}`]: {
    height: 190,
  },
  [`& .${classes.image}`]: {
    maxWidth: "250px"
  },
  [`& .${classes.loading}`]: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default function CardLoader(props: { isTabLoader?: boolean; }) {
  return (
    <StyledCard className={classes.card} style={{ position: props.isTabLoader ? 'inherit' : 'fixed' }}>
      <div className={classes.cardContainer} style={{ height: props.isTabLoader ? 'auto' : '100vh' }}>
        <div className={classes.loading}>
          <img className={classes.image} src="/wings.gif" alt="loading..." />
        </div>
        <Typography variant="h6" className={classes.loading}>Loading...</Typography>
      </div>
    </StyledCard>
  );
  // return (
  //   <StyledCard className={classes.card}>
  //     <CardHeader
  //       avatar={
  //         <Skeleton animation="wave" variant="circular" width={40} height={40} />
  //       }
  //       title={
  //         <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
  //       }
  //       subheader={<Skeleton animation="wave" height={10} width="40%" />}
  //     />
  //     <Skeleton animation="wave" variant="rectangular" className={classes.media} />

  //     <CardContent>
  //       <React.Fragment>
  //         <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
  //         <Skeleton animation="wave" height={10} width="80%" />
  //       </React.Fragment>
  //     </CardContent>
  //   </StyledCard>
  // );
}
