import { getTodayTimeInMilliSeconds, getTimeInMilliseconds } from './../helpers/date.helpers';
import { Path } from 'path-parser';
import { LoginDetails, ResetPassword } from '../components/login/login.types';
import { AddPartnerType } from '../components/home/my-business/business.types';
import { Agent, AreaLocation, KYCStatusUpdate, NamedItem, User, UserDetails, UserFilters } from '../types/user.types';
import { postAPI, getAPI, putAPI, getStaticAPI, deleteAPI } from './api';
import {
  LOGIN, MOBILE, USER,
  UPDATE_EMAIL, UPDATE_PASSWORD,
  PROFILE, BANK, KYC, ADD_PARTNER,
  MY_PARTNERS, PRODUCTS, MARCHANT_PRODUCTS,
  PRODUCT_IMAGES,
  ORDERS, USER_ORDERS, ORDER_TRACKING, STORE,
  MARCHANT_ORDERS, USER_ADDRESS,
  OTP, FORGOT_PASSWORD, USER_ORDER,
  PRODUCTS_SEARCH, PARTNER_DETAILS, MY_WALLET_TRANSACTIONS,
  MY_BUSINESS_TRANSACTIONS, MY_WALLET_SEND_TO_BANK,
  MY_WALLET_BALANCE, MY_BUSINESS_TRANSACTION_SEND_TO_BANK,
  NOTIFICATION_TOKEN, CASHFREE, PAYMENT_PROOF, MY_REFERRALS,
  USER_DASHBOARD_ORDERS, MARCHANT_DASHBOARD_ORDERS, ORDER_INVOICE,
  BASE_AGENT, AGENT, MARCHANT_PRODUCTS_STOCK_PRICE, PINCODE_USERS, KYC_STATUS,
} from './urls';
import { LocationStore, MessageType, Order, OrderFilterType, OrderInit, OrderInvoice, OrderTracking, Product, ProductFilters } from '../types/product.types';
import { PUBLISH_FILES } from '../types/publish.types';
import { getLocationName } from '../helpers/browser.helpers';
import { TransactionTracking, WalletSendToBank } from '../components/home/my-wallet/wallet.types';
import { DashboardFilter } from '../types/dashboard.types';

const getTimestamp = () => new Date().getTime();

let CONTENT_CONFIG: { [key in string]: number };
let isFetchingContentConfig = false;
export const getContentConfig = async (timestamp: number) => {
  if (CONTENT_CONFIG && CONTENT_CONFIG.expiry > getTimestamp()) {
    return CONTENT_CONFIG;
  }
  if (!isFetchingContentConfig) {
    isFetchingContentConfig = true;
    CONTENT_CONFIG = {};
    try {
      CONTENT_CONFIG = await getStaticAPI(`${PUBLISH_FILES.CONTENT_CONFIG}?timestamp=${timestamp}`);
    } catch (error) {
      console.error('Error while fetching content config...');
    }
    CONTENT_CONFIG.expiry = getTimestamp() + 3600000; // Add one hour
    isFetchingContentConfig = false;
    return CONTENT_CONFIG;
  } else {
    return new Promise<{ [key in string]: number }>((res) => {
      const interval = setInterval(() => {
        if (!isFetchingContentConfig) {
          clearInterval(interval);
          return res(CONTENT_CONFIG);
        }
      }, 500);
    });
  }
};

const getContentConfigTimeStamp = async (key: string) => {
  const config = await getContentConfig(getTimeInMilliseconds());
  return config[key] || config['latest'] || getTimeInMilliseconds();
};

getContentConfigTimeStamp('');

let STORES_LOCATION_CONFIG: { [key in string]: number };
export const getStoreLocationConfig = async () => {
  if (STORES_LOCATION_CONFIG && STORES_LOCATION_CONFIG.expiry > getTimestamp()) {
    return STORES_LOCATION_CONFIG;
  }
  STORES_LOCATION_CONFIG = {};
  try {
    STORES_LOCATION_CONFIG = await getStaticAPI(`${PUBLISH_FILES.STORES_LOCATION_CONFIG}?timestamp=${getTimeInMilliseconds()}`);
  } catch (error) {
    console.error('Error while fetching stores location config...');
  }
  STORES_LOCATION_CONFIG.expiry = getTimestamp() + 3600000; // Add one hour
  return STORES_LOCATION_CONFIG;
};

const getStoreLocationConfigTimeStamp = async (key: string) => {
  const config = await getStoreLocationConfig();
  return config[key] || config['latest'] || getTodayTimeInMilliSeconds;
};

let STORE_CONFIG: { [key in string]: number };
export const getStoreConfig = async () => {
  if (STORE_CONFIG && STORE_CONFIG.expiry > getTimestamp()) {
    return STORE_CONFIG;
  }
  STORE_CONFIG = {};
  try {
    STORE_CONFIG = await getStaticAPI(`${PUBLISH_FILES.STORE_CONFIG}?timestamp=${getTimeInMilliseconds()}`);
  } catch (error) {
    console.error('Error while fetching stores config...');
  }
  STORE_CONFIG.expiry = getTimestamp() + 3600000; // Add one hour
  return STORE_CONFIG;
};

// const getStoreConfigTimeStamp = async (key: string) => {
//   const config = await getStoreConfig();
//   return config[key] || config['latest'] || getTodayTimeInMilliSeconds;
// }

export const clearStaticConfig = () => {
  CONTENT_CONFIG = null as any;
  STORES_LOCATION_CONFIG = null as any;
  STORE_CONFIG = null as any;
};

const getPathBuilder = (url: string) => {
  return new Path(url);
};

export const loginAPI = async (input: LoginDetails): Promise<any> => {
  const url = getPathBuilder(LOGIN).build();
  return postAPI(url, input);
};

export const sendOTPAPI = async (input: ResetPassword): Promise<any> => {
  const url = getPathBuilder(OTP).build();
  return postAPI(url, input);
};

export const forgotPasswordAPI = async (input: ResetPassword): Promise<any> => {
  const url = getPathBuilder(FORGOT_PASSWORD).build();
  return postAPI(url, input);
};

export const signUpAPI = async (input: ResetPassword): Promise<any> => {
  const url = getPathBuilder(USER).build();
  return postAPI(url, input);
};

export const getUserAPI = async (): Promise<any> => {
  const url = getPathBuilder(USER).build();
  return getAPI(url);
};

export const getPincodeUsersAPI = async (filters: UserFilters): Promise<{ data: UserDetails[]; }> => {
  const url = `${PINCODE_USERS}?pincode=${filters.pincode}&isMerchant=${filters.isMerchant}&isVerified=${filters.isVerified}&kycStatus=${filters.kycStatus}`;
  return getAPI(url);
};

export const updateEmailAPI = async (input: User): Promise<any> => {
  const url = getPathBuilder(UPDATE_EMAIL).build();
  return putAPI(url, input);
};

export const updateNotificationTokenAPI = async (notificationToken: string): Promise<any> => {
  const url = getPathBuilder(NOTIFICATION_TOKEN).build();
  return putAPI(url, { notificationToken });
};

export const updateMobileAPI = async (input: User): Promise<any> => {
  const url = getPathBuilder(MOBILE).build();
  return putAPI(url, input);
};

export const getStoreMobileAPI = async (userId: string): Promise<any> => {
  const url = getPathBuilder(`${MOBILE}/${userId}`).build();
  return getAPI(url);
};

export const updatePasswordAPI = async (input: User): Promise<any> => {
  const url = getPathBuilder(UPDATE_PASSWORD).build();
  return putAPI(url, input);
};

export const getProfileAPI = async (userId?: string): Promise<any> => {
  const url = `${PROFILE}${userId ? `?userId=${userId}` : ''}`;
  return getAPI(url);
};

export const updateProfileAPI = async (input: UserDetails): Promise<any> => {
  const url = getPathBuilder(PROFILE).build();
  return putAPI(url, input);
};

export const updateProfileKYCStatusAPI = async (kyc: KYCStatusUpdate): Promise<any> => {
  const url = getPathBuilder(KYC_STATUS).build();
  return putAPI(url, kyc);
};

export const getCustomerAddressAPI = async (userId: string): Promise<any> => {
  const url = getPathBuilder(`${USER_ADDRESS}/${userId}`).build();
  return getAPI(url);
};

export const getPartnerDetailsAPI = async (userId: string): Promise<any> => {
  const url = getPathBuilder(`${PARTNER_DETAILS}/${userId}`).build();
  return getAPI(url);
};

export const updateStoreAPI = async (input: UserDetails): Promise<any> => {
  const url = getPathBuilder(STORE).build();
  return putAPI(url, input);
};

export const updateProfileKYCAPI = async (input: any): Promise<any> => {
  const url = getPathBuilder(KYC).build();
  return putAPI(url, input, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

export const getBankAPI = async (): Promise<any> => {
  const url = getPathBuilder(BANK).build();
  return getAPI(url);
};

export const updateBankAPI = async (input: UserDetails): Promise<any> => {
  const url = getPathBuilder(BANK).build();
  return putAPI(url, input);
};

export const getAllAgentsAPI = async (): Promise<{ data: Agent[]; }> => {
  const url = getPathBuilder(BASE_AGENT).build();
  return getAPI(url);
};

export const getAgentsAPI = async (agent: Agent): Promise<{ data: Agent[]; }> => {
  const url = getPathBuilder(AGENT).build();
  return putAPI(url, agent);
};

export const createAgentAPI = async (input: Agent): Promise<{ data: number, message: string; }> => {
  const url = getPathBuilder(BASE_AGENT).build();
  return postAPI(url, input);
};

export const updateAgentAPI = async (input: Agent): Promise<{ message: string; }> => {
  const url = getPathBuilder(`${BASE_AGENT}/${input.id}`).build();
  return putAPI(url, input);
};

export const deleteAgentAPI = async (id: number): Promise<{ message: string; }> => {
  const url = getPathBuilder(`${BASE_AGENT}/${id}`).build();
  return deleteAPI(url);
};

export const addPartnerAPI = async (input: AddPartnerType): Promise<any> => {
  const url = getPathBuilder(ADD_PARTNER).build();
  return postAPI(url, input);
};

export const getMyPartnersAPI = async (): Promise<any> => {
  const url = getPathBuilder(MY_PARTNERS).build();
  return getAPI(url);
};

export const getMyReferralsAPI = async (): Promise<any> => {
  const url = getPathBuilder(MY_REFERRALS).build();
  return getAPI(url);
};

export const getMyWalletTransactionsAPI = async (): Promise<any> => {
  const url = getPathBuilder(MY_WALLET_TRANSACTIONS).build();
  return getAPI(url);
};

export const updateMyWalletTransactionAPI = async (id: number, status: TransactionTracking): Promise<any> => {
  const url = getPathBuilder(`${MY_WALLET_TRANSACTIONS}/${id}`).build();
  return putAPI(url, status);
};

export const walletSendToBankAPI = async (transaction: WalletSendToBank): Promise<any> => {
  const url = getPathBuilder(MY_WALLET_SEND_TO_BANK).build();
  return postAPI(url, transaction);
};

export const myWalletBalanceAPI = async (): Promise<any> => {
  const url = getPathBuilder(MY_WALLET_BALANCE).build();
  return getAPI(url);
};

export const getMyBusinessTransactionsAPI = async (): Promise<any> => {
  const url = getPathBuilder(MY_BUSINESS_TRANSACTIONS).build();
  return getAPI(url);
};

export const updateMyBusinessTransactionAPI = async (id: number, status: TransactionTracking): Promise<any> => {
  const url = getPathBuilder(`${MY_BUSINESS_TRANSACTIONS}/${id}`).build();
  return putAPI(url, status);
};

export const businessTransactionSendToBankAPI = async (transaction: WalletSendToBank): Promise<any> => {
  const url = getPathBuilder(MY_BUSINESS_TRANSACTION_SEND_TO_BANK).build();
  return postAPI(url, transaction);
};

const getSearchFilterParams = (filters: ProductFilters) => `?brands=${filters.brands.length ? `${filters.brands}` : ''}&categories=${filters.categories.length ? `${filters.categories}` : ''}&sortBy=${filters.sortBy ? filters.sortBy : ''}&isSortAsc=${filters.isSortAsc}&page=${filters.page}&searchTerm=${filters.searchTerm || ''}&isVerified=${filters.isVerified || 'ALL'}`;

export const getProductsAPI = async (filters: ProductFilters): Promise<any> => {
  const url = `${PRODUCTS}${getSearchFilterParams(filters)}`;
  return getAPI(url);
};

export const getSearchProductsAPI = async (filters: ProductFilters): Promise<any> => {
  const url = `${PRODUCTS_SEARCH}${getSearchFilterParams(filters)}`;
  const config = {
    params: {
      stores: (filters.stores || []).map(store => store.id)
    }
  };
  return getAPI(url, config);
};

export const createProductAPI = async (product: Product): Promise<any> => {
  const url = getPathBuilder(PRODUCTS).build();
  return postAPI(url, product);
};

export const uploadProductImagesAPI = async (product: any): Promise<any> => {
  const url = getPathBuilder(PRODUCT_IMAGES).build();
  return postAPI(url, product, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

export const updateProductAPI = async (product: Product): Promise<any> => {
  const url = getPathBuilder(PRODUCTS).build();
  return putAPI(url, product);
};

// TODO delete if not required
// export const getMarchantProductsAPI = async (): Promise<any> => {
//   const url = getPathBuilder(MARCHANT_PRODUCTS).build();
//   return getAPI(url);
// };

// TODO delete if not required
// export const getAllMarchantProductsAPI = async (): Promise<any> => {
//   const url = getPathBuilder(ALL_MARCHANT_PRODUCTS).build();
//   return getAPI(url);
// };

export const createMarchantProductAPI = async (product: Product): Promise<any> => {
  const url = getPathBuilder(MARCHANT_PRODUCTS).build();
  return postAPI(url, product);
};

export const updateMarchantProductAPI = async (product: Product): Promise<any> => {
  const url = getPathBuilder(MARCHANT_PRODUCTS).build();
  return putAPI(url, product);
};

export const deleteMarchantProductAPI = async (productId: number): Promise<any> => {
  const url = getPathBuilder(`${MARCHANT_PRODUCTS}/${productId}`).build();
  return deleteAPI(url);
};

export const getMerchantTotalStockPriceAPI = async (): Promise<{ data: number; }> => {
  const url = getPathBuilder(`${MARCHANT_PRODUCTS_STOCK_PRICE}`).build();
  return getAPI(url);
};

interface OrderResponse { data: OrderInit; message: string; }

export const placeOrderAPI = async (order: Order): Promise<OrderResponse> => {
  const url = getPathBuilder(ORDERS).build();
  return postAPI(url, order);
};

export const uploadPaymentProofAPI = async (input: FormData): Promise<any> => {
  const url = getPathBuilder(PAYMENT_PROOF).build();
  return postAPI(url, input, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

export const getMyOrdersAPI = async <T>(type: OrderFilterType): Promise<T> => {
  const url = `${USER_ORDERS}?type=${type}`;
  return getAPI(url);
};

export const getMyOrderAPI = async (orderId: string): Promise<any> => {
  const url = getPathBuilder(`${USER_ORDER}/${orderId}`).build();
  return getAPI(url);
};

export const getMarchantOrdersAPI = async (type: OrderFilterType): Promise<any> => {
  const url = `${MARCHANT_ORDERS}?type=${type}`;
  return getAPI(url);
};

export const getOrdersForMarchantDashboardAPI = async (filters: DashboardFilter): Promise<any> => {
  const url = `${MARCHANT_DASHBOARD_ORDERS}?from=${filters.from}&to=${filters.to}`;
  return getAPI(url);
};

export const getOrdersForUserDashboardAPI = async (filters: DashboardFilter): Promise<any> => {
  const url = `${USER_DASHBOARD_ORDERS}?from=${filters.from}&to=${filters.to}`;
  return getAPI(url);
};

export const getOrdersInvoiceAPI = async (invoice: OrderInvoice): Promise<any> => {
  const url = `${ORDER_INVOICE}`;
  const config = {
    responseType: 'blob' as any,
  };
  return putAPI(url, invoice, config);
};

export const sendOrdersInvoiceAPI = async (invoice: OrderInvoice, type: MessageType): Promise<any> => {
  const url = `${ORDER_INVOICE}/${type}`;
  return putAPI(url, invoice);
};

export const updateOrderStatusAPI = async (orderId: number, status: OrderTracking): Promise<any> => {
  const url = getPathBuilder(`${ORDER_TRACKING}/${orderId}`).build();
  return putAPI(url, status);
};

export const getCashfreeOrderStatusAPI = async (orderId: number): Promise<any> => {
  const url = getPathBuilder(`${CASHFREE}/${orderId}`).build();
  return getAPI(url);
};








// Static CDN APIs
export const getFAQsAPI = async (): Promise<any> => {
  const key = PUBLISH_FILES.FAQ;
  return getStaticAPI(`${key}?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getBrandsAPI = async (): Promise<any> => {
  const key = PUBLISH_FILES.BRANDS;
  return getStaticAPI(`${key}?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getCategoriesAPI = async (): Promise<any> => {
  const key = PUBLISH_FILES.CATEGORIES;
  return getStaticAPI(`${key}?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getQuantitiesAPI = async (): Promise<any> => {
  const key = PUBLISH_FILES.QUANTITIES;
  return getStaticAPI(`${key}?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getStatesAPI = async (): Promise<any> => {
  const key = PUBLISH_FILES.PINCODES;
  return getStaticAPI(`${key}/states.json?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getDistrictsAPI = async (state: string): Promise<any> => {
  const key = PUBLISH_FILES.PINCODES;
  state = getLocationName(state);
  return getStaticAPI(`${key}/${state}/${state}.json?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getVillagesAPI = async (state: string, district: string): Promise<any> => {
  const key = PUBLISH_FILES.PINCODES;
  state = getLocationName(state);
  district = getLocationName(district);
  return getStaticAPI(`${key}/${state}/${district}/${district}.json?timestamp=${await getContentConfigTimeStamp(key)}`);
};

export const getPincodeLocationAPI = async (pincode: string): Promise<AreaLocation> => {
  return getStaticAPI(`${PUBLISH_FILES.PINCODE(pincode)}?timestamp=${await getContentConfigTimeStamp(PUBLISH_FILES.PINCODES)}`);
};

export const getLocationStoresAPI = async (pincode: string, isSubString = false): Promise<LocationStore[]> => {
  pincode = getLocationName(pincode);
  return getStaticAPI(`${PUBLISH_FILES.STORE_LOCATION(pincode)}?timestamp=${await getStoreLocationConfigTimeStamp(PUBLISH_FILES.STORE_LOCATION_PATH(pincode, isSubString))}`);
};

export const getStoreDetailsAPI = async (marchantId: string): Promise<any> => {
  return getStaticAPI(`${PUBLISH_FILES.STORE_DETAILS(marchantId)}?timestamp=${getTimestamp()}`);
};

export const getStoreProductsAPI = async (storeId: string): Promise<Product[]> => {
  return getStaticAPI(`${PUBLISH_FILES.STORE_PRODUCTS(storeId)}?timestamp=${getTimestamp()}`);
};

export const getStoreProductAPI = async (storeId: string, productId: number): Promise<Product> => {
  return getStaticAPI(`${PUBLISH_FILES.STORE_PRODUCT(storeId, productId)}?timestamp=${getTimestamp()}`);
};

export const getBaseProductAPI = async (productId: number): Promise<Product> => {
  return getStaticAPI(`${PUBLISH_FILES.PRODUCT(productId)}?timestamp=${getTimestamp()}`);
};

export const getStoreTypesAPI = async (): Promise<NamedItem[]> => {
  return getStaticAPI(`${PUBLISH_FILES.STORE_TYPES}?timestamp=${getTimestamp()}`);
};

export const getProfessionsAPI = async (): Promise<NamedItem[]> => {
  return getStaticAPI(`${PUBLISH_FILES.PROFESSIONS}?timestamp=${getTimestamp()}`);
};
