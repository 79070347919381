import { isLocalhost } from "../helpers/browser.helpers";

const PRODUCTS = 'commerce/products';
const STORES = 'commerce/stores';
const CONFIG = 'config';
export const pathPrefix = isLocalhost ? 'local/' : '';
export const PUBLISH_FILES = {
  CONTENT_CONFIG: `${pathPrefix}${CONFIG}/content-config.json`,
  STORE_CONFIG: `${pathPrefix}${CONFIG}/stores-config.json`,
  STORES_LOCATION_CONFIG: `${pathPrefix}${CONFIG}/stores-location-config.json`,
  CATEGORIES: `${pathPrefix}commerce/categories/categories.json`,
  QUANTITIES: `${pathPrefix}commerce/quantities/quantities.json`,
  BRANDS: `${pathPrefix}commerce/brands/brands.json`,
  FAQ: `${pathPrefix}support/faqs.json`,
  STORE_TYPES: `${pathPrefix}${STORES}/store-types.json`,
  PROFESSIONS: `${pathPrefix}users/professions.json`,
  PINCODES: 'pincodes',
  PINCODE: (pincode: string) => `pincodes/pincode/${pincode}.json`,

  PRODUCT: (id: number) => `${pathPrefix}${PRODUCTS}/${id}/product.json`,

  STORE_LOCATION_PATH: (pincode: string, isSubString: boolean) => `${pathPrefix}${STORES}/location/${isSubString ? pincode.substr(0, 3) : pincode}`,
  STORE_LOCATION: (pincode: string) => `${pathPrefix}${STORES}/location/${pincode}.json`,

  STORE_DETAILS: (storeId: string) => `${pathPrefix}${STORES}/${storeId}/store.json`,
  STORE_PRODUCT: (storeId: string, id: number) => `${pathPrefix}${STORES}/${storeId}/products/${id}/product.json`,
  STORE_PRODUCTS: (storeId: string) => `${pathPrefix}${STORES}/${storeId}/all-products.json`,
  SUBSCRIPTION: (subscription: string) => `${pathPrefix}subscription/${subscription}.png`,
};