import {
    createAgentAPI,
    deleteAgentAPI,
    getAgentsAPI,
    getAllAgentsAPI,
    getBankAPI, getCustomerAddressAPI, getPartnerDetailsAPI, getPincodeUsersAPI, getProfessionsAPI, getProfileAPI, getStoreDetailsAPI, getStoreMobileAPI, getStoreTypesAPI, getUserAPI,
    loginAPI, updateAgentAPI, updateBankAPI, updateEmailAPI,
    updateMobileAPI, updateNotificationTokenAPI, updatePasswordAPI, updateProfileAPI, updateProfileKYCAPI, updateProfileKYCStatusAPI, updateStoreAPI
} from '../backend/backend';
import { clearAuthorizationToken } from '../helpers/api.helpers';
import { AgentsMap, UserState } from '../types/store.types';
import { Agent, KYCStatusUpdate, UploadFiles, User, UserDetails, UserFilters } from '../types/user.types';
import { handleAPIError, setUserState, showSnackbar } from './store.service';

export const userState: UserState = ({
    user: null as any,
    userDetails: null as any,
    isLoaded: false,
    isAuthenticated: false,
    stores: {},
    customers: {},
    partners: {},
    storeTypes: [],
    professions: [],
    allAgents: [],
    agents: {} as AgentsMap,
    setIsLoaded: (isLoaded) => setUserState({ isLoaded }),
    getUser: () => getUser(),
    logOut: () => logOut(),
    updateEmail: (user) => updateEmail(user),
    updateMobile: (user) => updateMobile(user),
    updatePassword: (user) => updatePassword(user),
    getProfile: () => getProfile(),
    getUserProfile: (userId: string) => getUserProfile(userId),
    updateProfile: (user) => updateProfile(user),
    updateStore: (user) => updateStore(user),
    updateProfileKYC: (user, files) => updateProfileKYC(user, files),
    updateProfileKYCStatus: (kyc) => updateProfileKYCStatus(kyc),
    updateNotificationToken: (token) => updateNotificationToken(token),
    authenticate: (user) => authenticate(user),
    getBank: () => getBank(),
    updateBank: (user) => updateBank(user),
    getStoreDetails: (marchantId) => getStoreDetails(marchantId),
    getCustomerDetails: (userId) => getCustomerDetails(userId),
    getPartnerDetails: (userId) => getPartnerDetails(userId),
    getStoreMobile: (marchantId) => getStoreMobile(marchantId),
    getStoreTypes: () => getStoreTypes(),
    getProfessions: () => getProfessions(),
    getAgents: (agent) => getAgents(agent),
    getAllAgents: () => getAllAgents(),
    createAgent: (agent) => createAgent(agent),
    updateAgent: (agent) => updateAgent(agent),
    deleteAgent: (id) => deleteAgent(id),
    getPincodeUsers: (filters: UserFilters) => getPincodeUsers(filters),
});

const authenticate = async (user: User) => {
    let isSucceed = false;
    try {
        const response = await loginAPI({ mobile: user.userId, password: user.password });
        if (response && response.data) {
            isSucceed = true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Authentication error " });
    }
    return isSucceed;
};
const getUser = async () => {
    try {
        const response = await getUserAPI();
        if (response && response.data) {
            setUserState({ user: response.data, isAuthenticated: true, isLoaded: true });
        }
    } catch (error: any) {
        setUserState({ isLoaded: true });
        handleAPIError({ error, message: "get user error ", showSnackbar: false });
    }
};

const logOut = () => {
    setUserState({ isAuthenticated: false, user: null as any });
    clearAuthorizationToken();
};

const updateEmail = async (user: User) => {
    let isSucceed = false;
    try {
        const response = await updateEmailAPI(user);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update email error " });
    } finally {
        return isSucceed;
    }
};

const updateNotificationToken = async (token: string) => {
    let isSucceed = false;
    try {
        const response = await updateNotificationTokenAPI(token);
        if (response) {
            isSucceed = true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update notification token error " });
    } finally {
        return isSucceed;
    }
};

const updateMobile = async (user: User) => {
    let isSucceed = false;
    try {
        const response = await updateMobileAPI(user);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update mobile number error " });
    } finally {
        return isSucceed;
    }
};

const updatePassword = async (user: User) => {
    let isSucceed = false;
    try {
        const response = await updatePasswordAPI(user);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update password error " });
    } finally {
        return isSucceed;
    }
};

const getProfile = async () => {
    let isSucceed = false;
    try {
        const response = await getProfileAPI();
        if (response && response.data) {
            setUserState({ userDetails: response.data });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get profile error " });
    } finally {
        return isSucceed;
    }
};

const getUserProfile = async (userId: string) => {
    try {
        const response = await getProfileAPI(userId);
        return response?.data;
    } catch (error: any) {
        handleAPIError({ error, message: "Get user profile error " });
    }
};

const getCustomerDetails = async (userId: string) => {
    try {
        const response = await getCustomerAddressAPI(userId);
        if (response && response.data) {
            setUserState((state) => ({ customers: { ...state.customers, [userId]: response.data } }));
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get customer details error " });
    }
};

const getPartnerDetails = async (userId: string) => {
    try {
        const response = await getPartnerDetailsAPI(userId);
        if (response && response.data) {
            setUserState((state) => ({ partners: { ...state.partners, [userId]: response.data } }));
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get partners details error " });
    }
};


const updateProfileKYC = async (user: UserDetails, files?: UploadFiles) => {
    const fileKeys = Object.keys(files || {});
    if (fileKeys.length > 0) {
        try {
            const formData = new FormData();
            fileKeys.forEach(key => formData.append(key, (files as any)[key]));
            const response = await updateProfileKYCAPI(formData);
            if (response && response.data) {
                const imageURLS: { [key in keyof UploadFiles]: string } = response.data;
                if (imageURLS.profile) {
                    user.userImageURL = imageURLS.profile;
                }
                if (imageURLS.aadhaar) {
                    user.kyc.aadhaar.image = imageURLS.aadhaar;
                }
                if (imageURLS.pan) {
                    user.kyc.pan.image = imageURLS.pan;
                }
                if (imageURLS.storeImage) {
                    user.store.storeImage = imageURLS.storeImage;
                }
                if (imageURLS.paymentQR) {
                    user.store.payment.paymentQR = imageURLS.paymentQR;
                }
            }
        } catch (error: any) {
            handleAPIError({ error, message: "Update KYC error " });
            return false;
        }
    }
    return updateProfile(user);
};

const updateProfile = async (user: UserDetails) => {
    let isSucceed = false;
    try {
        const response = await updateProfileAPI(user);
        if (response) {
            isSucceed = true;
            setUserState({ userDetails: user, user });
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update profile error " });
    } finally {
        return isSucceed;
    }
};

const updateProfileKYCStatus = async (kyc: KYCStatusUpdate) => {
    let isSucceed = false;
    try {
        const response = await updateProfileKYCStatusAPI(kyc);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update KYC status error " });
    } finally {
        return isSucceed;
    }
};

const updateStore = async (user: UserDetails) => {
    let isSucceed = false;
    try {
        const response = await updateStoreAPI(user);
        if (response) {
            isSucceed = true;
            setUserState((state) => {
                const userDetails = { ...state.userDetails };
                userDetails.isMarchant = user.isMarchant;
                userDetails.store = user.store;
                return { userDetails, user: userDetails };
            });
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update profile error " });
    } finally {
        return isSucceed;
    }
};

const getBank = async () => {
    let user: UserDetails = null as any;
    try {
        const response = await getBankAPI();
        if (response && response.data) {
            user = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get bank error " });
    } finally {
        return user;
    }
};

const updateBank = async (user: UserDetails) => {
    let isSucceed = false;
    try {
        const response = await updateBankAPI(user);
        if (response) {
            isSucceed = true;
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update bank error " });
    } finally {
        return isSucceed;
    }
};

const getStoreDetails = async (marchantId: string) => {
    try {
        const store = await getStoreDetailsAPI(marchantId);
        if (store) {
            setUserState((state) => ({ stores: { ...state.stores, [marchantId]: store } }));
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get store details error " });
    }
};

const getStoreTypes = async () => {
    try {
        const storeTypes = await getStoreTypesAPI();
        if (storeTypes) {
            setUserState({ storeTypes });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get store types error " });
    }
};

const getProfessions = async () => {
    try {
        const professions = await getProfessionsAPI();
        if (professions) {
            setUserState({ professions: [...professions, { id: -1, name: 'Other' }] });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get professions error " });
    }
};

const getStoreMobile = async (marchantId: string) => {
    let mobile = '';
    try {
        const response = await getStoreMobileAPI(marchantId);
        if (response && response.data) {
            mobile = response.data;
            setUserState((state) => ({ stores: { ...state.stores, [marchantId]: { ...(state.stores[marchantId] || {}), mobile: response.data } } }));
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get store details error " });
    }
    return mobile;
};

const getAllAgents = async () => {
    try {
        const response = await getAllAgentsAPI();
        if (response && response.data) {
            setUserState({ allAgents: response.data });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while getting all agents!" });
    }
};

const getAgents = async (agent: Agent) => {
    try {
        const response = await getAgentsAPI(agent);
        if (response && response.data) {
            const agents = {} as AgentsMap;
            response.data.forEach(agent => agents[agent.type] = agent);
            setUserState({ agents });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while getting agents!" });
    }
};

const createAgent = async (agent: Agent) => {
    let isSucceed = false;
    try {
        const response = await createAgentAPI(agent);
        if (response && response.data) {
            setUserState((state) => ({ allAgents: [...state.allAgents, { ...agent, id: response.data }] }));
            showSnackbar(response.message);
            isSucceed = true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while creating agent!", showSnackbar: true });
    } finally {
        return isSucceed;
    }
};

const updateAgent = async (agent: Agent) => {
    let isSucceed = false;
    try {
        const response = await updateAgentAPI(agent);
        isSucceed = true;
        if (response && response.message) {
            setUserState((state) => ({ allAgents: state.allAgents.map(a => a.id === agent.id ? agent : a) }));
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while updating agent!", showSnackbar: true });
    } finally {
        return isSucceed;
    }
};

const deleteAgent = async (id: number) => {
    let isSucceed = false;
    try {
        const response = await deleteAgentAPI(id);
        isSucceed = true;
        setUserState((state) => ({ allAgents: state.allAgents.filter(agent => agent.id !== id) }));
        if (response && response.message) {
            showSnackbar(response.message);
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while deleting agent!", showSnackbar: true });
    } finally {
        return isSucceed;
    }
};

const getPincodeUsers = async (filters: UserFilters) => {
    try {
        const response = await getPincodeUsersAPI(filters);
        if (response?.data) {
            return response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Error while getting pincode users!" });
    }
    return [];
};
