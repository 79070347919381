const BASE_API = 'api';
const BASE_USERS = `${BASE_API}/users`;
export const LOGIN = `${BASE_USERS}/login`;
export const OTP = `${BASE_USERS}/otp`;
export const FORGOT_PASSWORD = `${BASE_USERS}/forgot-password`;
export const USER = `${BASE_USERS}/user`;
export const USER_ADDRESS = `${BASE_USERS}/user-address`;
export const PARTNER_DETAILS = `${BASE_USERS}/partner`;
export const UPDATE_EMAIL = `${BASE_USERS}/email`;
export const MOBILE = `${BASE_USERS}/mobile`;
export const UPDATE_PASSWORD = `${BASE_USERS}/password`;
export const PROFILE = `${BASE_USERS}/profile`;
export const STORE = `${BASE_USERS}/store`;
export const KYC = `${BASE_USERS}/kyc`;
export const BANK = `${BASE_USERS}/bank`;
export const ADD_PARTNER = `${BASE_USERS}/partner`;
export const MY_PARTNERS = `${BASE_USERS}/my-partners`;
export const MY_REFERRALS = `${BASE_USERS}/my-referrals`;
export const NOTIFICATION_TOKEN = `${BASE_USERS}/notification-token`;
export const PINCODE_USERS = `${BASE_USERS}/pincode-users`;
export const KYC_STATUS = `${BASE_USERS}/update-kyc-status`;

export const BASE_AGENT = `${BASE_API}/agents`;
export const AGENT = `${BASE_AGENT}/agent`;

const BASE_BUSINESS = `${BASE_API}/business`;
export const MY_BUSINESS_TRANSACTIONS = `${BASE_BUSINESS}/transactions`;
export const MY_BUSINESS_TRANSACTION_SEND_TO_BANK = `${BASE_BUSINESS}/sent-to-bank`;

const BASE_WALLET = `${BASE_API}/wallet`;
export const MY_WALLET_TRANSACTIONS = `${BASE_WALLET}/transactions`;
export const MY_WALLET_SEND_TO_BANK = `${BASE_WALLET}/sent-to-bank`;
export const MY_WALLET_BALANCE = `${BASE_WALLET}/balance`;

const BASE_PRODUCTS = `${BASE_API}/products`;
export const PRODUCTS = `${BASE_PRODUCTS}`;
export const PRODUCTS_SEARCH = `${BASE_PRODUCTS}/search`;
export const MARCHANT_PRODUCTS = `${BASE_PRODUCTS}/marchant`;
export const MARCHANT_PRODUCTS_STOCK_PRICE = `${BASE_PRODUCTS}/marchant-stock-price`;
export const ALL_MARCHANT_PRODUCTS = `${BASE_PRODUCTS}/all-marchant`;
export const PRODUCT_IMAGES = `${BASE_PRODUCTS}/images`;

const BASE_ORDERS = `${BASE_API}/orders`;
export const ORDERS = `${BASE_ORDERS}`;
export const PAYMENT_PROOF = `${BASE_ORDERS}/payment-proof`;
export const USER_ORDERS = `${BASE_ORDERS}/user`;
export const USER_ORDER = `${BASE_ORDERS}/order`;
export const ORDER_INVOICE = `${BASE_ORDERS}/invoice`;
export const MARCHANT_ORDERS = `${BASE_ORDERS}/marchant`;
export const MARCHANT_DASHBOARD_ORDERS = `${BASE_ORDERS}/marchant-dashboard`;
export const USER_DASHBOARD_ORDERS = `${BASE_ORDERS}/user-dashboard`;
export const ORDER_TRACKING = `${BASE_ORDERS}/tracking`;
export const CASHFREE = `${BASE_ORDERS}/cashfree`;
