import { clearLocalStorageToken, getLocalStorageItem, setLocalStorageItem } from "./browser.helpers";

const VSTEP_TOKEN_KEY = 'token';

export function getAuthorizationToken() {
    return getLocalStorageItem(VSTEP_TOKEN_KEY);
}

export function setAuthorizationToken(token: string) {
    return setLocalStorageItem(VSTEP_TOKEN_KEY, token);
}

export function clearAuthorizationToken() {
    return clearLocalStorageToken(VSTEP_TOKEN_KEY);
}
