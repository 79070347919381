import { STATIC_PATH } from "../types/constants";
import { Order, OrderInvoice, PaymentStatus, Product, Quantity } from "../types/product.types";
import { pathPrefix } from "../types/publish.types";
import { Store, User, UserDetails, VSubscriptions } from "../types/user.types";
import { formatPriceDecimal } from "./browser.helpers";
import { getDateInDateStringFormat } from "./date.helpers";
import { getInvoicePaymentStatus } from "./order.helpers";

export const getURLFromString = (url: string) => (url || '').replace(/[^a-zA-Z0-9-_]/g, '-').replace(/-+/g, '-').toLowerCase();

export const filterProductsBySearchTerm = (products: Product[], searchTerm: string, sortProperty = '', asc = true): Product[] => {
  let searchedProducts: Product[] = [];
  const results: { product: Product, matchCount: number; }[] = [];
  if (searchTerm && searchTerm.length > 1) {
    const searchTerms = searchTerm.toLowerCase().trim().split(' ');
    for (const product of products) {
      const productName = product.name.toLowerCase();
      let matchCount = 0;
      for (const searchTerm of searchTerms) {
        if (productName.includes(searchTerm)) {
          matchCount++;
        }
      }
      if (matchCount > 0) {
        results.push({ product, matchCount });
      }
    }

    // Sort results by matchCount in descending order
    results.sort((a, b) => {
      return b.matchCount - a.matchCount;
    });

    // Extract product names from the sorted results
    searchedProducts = results.map((result) => result.product);
  } else {
    searchedProducts = products;
    if (sortProperty) {
      searchedProducts = searchedProducts.sort((p1: any, p2: any) => {
        let a = p1[sortProperty];
        let b = p2[sortProperty];
        if (asc) {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a > b) {
            return -1;
          } else if (a < b) {
            return 1;
          } else {
            return 0;
          }
        }
      }) as Product[];
    }
  }
  return searchedProducts;
};

export const getIsStorePrice = (user: User, marchantId?: string) => {
  return !user || !user?.userId || !VSubscriptions.includes(user?.subscription) ||
    (!!marchantId && marchantId === user?.userId);
};

export const getImageURL = (url: string) => {
  if (!url) {
    return '';
  }
  return url.startsWith('http') || url.startsWith('data:') ? url : `${STATIC_PATH}/${url.startsWith(pathPrefix) ? '' : pathPrefix}${url}`;
};

export const getQuantitiesMap = (quantities: Quantity[]) => {
  return quantities.reduce((quantityMap, quantity) => {
    quantityMap[quantity.id] = quantity.name;
    return quantityMap;
  }, {} as { [key in string]: string });
};

export const generateInvoiceInput = (order: Order, store: Store, quantitiesMap: { [key in string]: string }): OrderInvoice => {
  const customer = order.orderDetails.delivery;
  const invoice = {} as OrderInvoice;
  invoice.store = {
    storeName: store.storeName,
    address: store.address || {}
  } as Store;
  invoice.customer = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    address: customer.address || {},
    mobile: customer.mobile,
  } as UserDetails;
  invoice.order = {
    invoiceId: `${order.id}`,
    date: order.orderDetails.tracking ? getDateInDateStringFormat(order.orderDetails.tracking[0].date) : '',
    paymentMode: getInvoicePaymentStatus(order.orderDetails.payment.paymentStatus as PaymentStatus),
    items: []
  };
  order.orderDetails.products.forEach(product => {
    invoice.order.items.push({
      name: product.name,
      mrp: `${formatPriceDecimal(product.mrp * (product.cartQuantity || 1))}`,
      quantity: `${(product.cartQuantity || 1) > 1 ? product.cartQuantity + ' x ' : ''} ${product.packOf > 1 ? 'Pack of ' + product.packOf + ' x ' : ''} ${product.quantity} ${quantitiesMap[product.quantityType]}`,
      discount: `${formatPriceDecimal((product.mrp - (order.orderDetails.isStorePrice ? product.storePrice : product.vPrice)) * (product.cartQuantity || 1))}`,
      total: `${formatPriceDecimal((order.orderDetails.isStorePrice ? product.storePrice : product.vPrice) * (product.cartQuantity || 1))}`,
    });
  });
  invoice.order.items.push({
    name: "Total :",
    mrp: "",
    quantity: "",
    discount: "",
    total: `${formatPriceDecimal(+order.price)}`,
  });
  invoice.order.items.push({
    name: "Total Savings :",
    mrp: "",
    quantity: "",
    discount: "",
    total: `${formatPriceDecimal(order.orderDetails.isStorePrice ? order.orderDetails.cart.storeDiscount : order.orderDetails.cart.vDiscount)}`,
  });
  return invoice;
};

export const ALL = 'ALL';

export const booleanFilters: {
  label: string;
  value: string;
}[] = [{ label: 'All', value: ALL }, { label: 'Yes', value: 'YES' }, { label: 'No', value: 'NO' }];

