import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "../../types/routes";
import { clearStore, useStore } from "../../services/store.service";

const renderMergedProps = (component: any, ...props: any) => {
  const finalProps = Object.assign({}, ...props);
  return React.createElement(component, finalProps);
};

/**
 * Wrapper around the Router component, which check authorization
 * to it's child.
 * Taken from https://github.com/ReactTraining/react-router/issues/4105
 */
const AuthRoute = ({ component, ...props }: { [x: string]: any; component: any; }) => {
  const { isAuthenticated } = useStore(state => state.user);
  const path = `${props.path}`;
  let redirectTo = '';
  const isLoginRoute = [ROUTES.login.route, ROUTES.signup.route, ROUTES.forgotPassword.route].indexOf(path) > -1;
  if (!isAuthenticated && path === '/') {
    redirectTo = ROUTES.home.subRoutes.landing.route;
  } else if (!isAuthenticated && !isLoginRoute) {
    redirectTo = ROUTES.login.route;
    clearStore();
  } else if (isAuthenticated && isLoginRoute) {
    redirectTo = ROUTES.home.route;
  }

  return !redirectTo ?
    <Route
      {...props}
      render={routeProps => renderMergedProps(component, routeProps, props)}
    />
    :
    <Redirect
      to={redirectTo}
    />;
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node])
};

export default AuthRoute;
