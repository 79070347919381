import create, { SetState } from 'zustand';
import { snackBarState } from '../components/shared/snakckbar';
import { getLocalStorageJSON, setLocalStorageJSON } from '../helpers/browser.helpers';
import { isDevMode } from '../helpers/config.helpers';
import {
  SnackBarState, SnackBarType, Store,
  UserState, BusinessState, ProductsState, SupportState, OrdersState, HandleAPIError, WalletState
} from '../types/store.types';
import { addressState } from './address.service';
import { businessState } from './business.service';
import { ordersState } from './orders.service';
import { productsState } from './products.service';
import { supportState } from './support.service';
import { userState } from './user.service';
import { walletState } from './wallet.service';

export const setUserState: SetState<UserState> = (input) => {
  setState((state) => ({ user: { ...state.user, ...getUpdatedState(state.user, input) } }));
};

export const setSnackBarState: SetState<SnackBarState> = (input) => {
  setState((state) => ({ snackBar: { ...state.snackBar, ...getUpdatedState(state.snackBar, input) } }));
};

export const showSnackbar = (message: string | JSX.Element, type?: SnackBarType, onClick?: () => void) => {
  setSnackBarState({ message, isOpen: true, type: type || 'success', onClick });
};

export const setBusinessState: SetState<BusinessState> = (input) => {
  setState((state) => ({ business: { ...state.business, ...getUpdatedState(state.business, input) } }));
};

export const setProductsState: SetState<ProductsState> = (input) => {
  setState((state) => ({ products: { ...state.products, ...getUpdatedState(state.products, input) } }));
};

export const setSupportState: SetState<SupportState> = (input) => {
  setState((state) => ({ support: { ...state.support, ...getUpdatedState(state.support, input) } }));
};

const getUpdatedState = (state: any, input: any) => (typeof input === 'function' ? input(state) : input);

export const setOrderState: SetState<OrdersState> = (input) => {
  setState((state) => ({ orders: { ...state.orders, ...getUpdatedState(state.orders, input) } }));
};

export const setWalletState: SetState<WalletState> = (input) => {
  setState((state) => ({ wallet: { ...state.wallet, ...getUpdatedState(state.wallet, input) } }));
};

export const clearStore = () => {
  setState({ ...initialState });
};

export const handleAPIError = (error: HandleAPIError) => {
  console.error(error.message, error);
  if (error.error?.response?.data?.data === 403) {
    setUserState({ isAuthenticated: false });
  } else if (error.showSnackbar !== false && (error.error?.response?.data?.message || error.message)) {
    showSnackbar(error.error?.response?.data?.message || error.message, 'error');
  }
};

let setState: SetState<Store>;
let initialState: Store = {
  user: userState,
  snackBar: snackBarState,
  business: businessState,
  products: productsState,
  support: supportState,
  orders: ordersState,
  address: addressState,
  wallet: walletState
};
export const useStore = create<Store>(set => {
  setState = set;
  return { ...initialState };
});

if (isDevMode) {
  useStore.subscribe((cur, prev) => console.log("State: ", prev, cur));
}

const CART_LOCAL_STORAGE = 'cart';
window.onload = (() => {
  const cartItems = getLocalStorageJSON(CART_LOCAL_STORAGE);
  setOrderState({ cartItems: cartItems || [] });
});

window.onbeforeunload = (() => {
  setState((state) => {
    setLocalStorageJSON(CART_LOCAL_STORAGE, state.orders.cartItems);
    return state;
  });
});
