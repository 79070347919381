export type Role = 'SUPER_ADMIN' | 'AGENT';

export type Subscription = 'FREE' | 'BRONZE' | 'SILVER' | 'GOLD' | 'PLATINUM' | 'DIAMOND';

export const VSubscriptions: Subscription[] = ['BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND'];

export interface User {
    id: number;
    userId: string;//user_id
    mobile: string;
    email: string;
    subscription: Subscription;
    roles: Role[];
    isActive: boolean; //is_active
    isVerified: boolean;
    password: string;
    newPassword?: string; // for change password
    firstName: string;//UserDetails
    lastName: string;//UserDetails
    isMarchant: boolean;//UserDetails
}

export type PartnerNetwork = { [key in string]: PartnerNetwork };
export interface UserDetails extends User {
    network: PartnerNetwork;
    firstName: string;//first_name
    lastName: string;//last_name
    referrerId: string;//referrer_id
    partnerId: string;//partner_id
    bankDetails: BankDetails;//bank_details
    kyc: KYC;//kyc
    store: Store;
    address: Address;
    pincode: number;
    pickupPincode: number; //pickup_pincode
    userImageURL: string;//user_image_url
    aadhaarUpload?: File | null;// for aadhaar upload
    isMarchant: boolean;
    notificationTokens: {
        web: string[];
    };
    properties: {
        profession: {
            type: number;
            other: string;
        };
    };
    createdDate: string;
}

export type KYCStatus = 'NOT_SUBMITTED' | 'UNDER_VERIFICATION' | 'VERIFIED' | 'REJECTED';
export interface KYC {
    status: KYCStatus;
    rejectionReason: string;
    dob: Date | null;
    aadhaar: {
        number: string;
        image: string;
    },
    pan: {
        number: string;
        image: string;
    },
    store: {
        gst: string;
    };
}

export const getKYCStatus = (status: KYCStatus) => {
    switch (status) {
        case 'NOT_SUBMITTED': return 'Not Submitted';
        case 'UNDER_VERIFICATION': return 'Under Verification';
        case 'VERIFIED': return 'Verified';
        case 'REJECTED': return 'Rejected';
        default: return 'Not Submitted';
    }
};

export interface Delivery {
    deliveryTypes: DeliveryType[];
    deliveryBy: DeliveryByType;
    deliveryCharges: number;
    states: string[];
    districts: string[];
    villages: Village[];
}

export interface AreaLocation {
    village: string;
    pincode: string;
    officeType: 'PO' | 'HO' | 'BO';
    district: string;
    state: string;
}

export type Village = Pick<AreaLocation, 'village' | 'pincode'>;

export interface NamedItem {
    id: number;
    name: string;
}

export interface StringId {
    id: string;
}

export const PaymentTypes = ['CASH', 'DIRECT_QR_TO_CUSTOMER', 'PAY_TO_VSTEP', 'V_WALLET', 'PAY_TO_CASHFREE'] as const;
export type PaymentType = typeof PaymentTypes[number];

export const CustomerPaymentTypes: { [key in PaymentType]: string } = {
    'V_WALLET': 'V Wallet',
    'CASH': 'Cash on delivery',
    'DIRECT_QR_TO_CUSTOMER': 'Pay to store QR code',
    'PAY_TO_VSTEP': 'Paytm: Pay through Credit/Debit cards, Online banking and UPI (Charges might apply)',
    'PAY_TO_CASHFREE': 'CashFree: Pay through Credit/Debit cards, Online banking and UPI (Charges might apply)'
};

export const OrderPaymentTypes: { [key in PaymentType]: string } = {
    'V_WALLET': 'V Wallet',
    'CASH': 'Cash on delivery',
    'DIRECT_QR_TO_CUSTOMER': 'Store QR code',
    'PAY_TO_VSTEP': 'Online',
    'PAY_TO_CASHFREE': 'Online'
};

export type DeliveryType = 'HOME_DELIVERY' | 'STORE_PICKUP';

export type DeliveryByType = 'SELF_DELIVERY' | 'VSTEP_AGENT_DELIVERY';

export const CustomerDeliveryTypes: { [key in DeliveryType]: string } = {
    'HOME_DELIVERY': 'Deliver to Home',
    'STORE_PICKUP': 'Pickup from Store'
};

export interface StorePayment {
    paymentQR: string;
    paymentTypes: PaymentType[];
}

export interface Store {
    storeDate: Date | null;
    storeName: string;
    storeType: number;
    storeTypeOther: string;
    address: Address;
    delivery: Delivery;
    storeImage: string;
    payment: StorePayment;
    serviceCharge: number;
    isInStorePurpose: boolean;
}

export interface Address {
    isPermanent: boolean;
    sameAsPermanent: boolean;
    houseNo: string;
    street: string;
    village: string;
    mandal: string;
    district: string;
    state: string;
    pincode: number;
    location: Location;
}

export interface BankDetails {
    accountNo: string;
    accountName: string;
    ifscCode: string;
    confirmAccountNo?: string;
}

export interface Location {
    latitude: number;
    longitude: number;
}

export interface UploadFiles {
    profile: File | null;
    aadhaar: File | null;
    pan: File | null;
    storeImage: File | null;
    paymentQR: File | null;
}

export interface NamedItem {
    id: number;
    name: string;
}

export const AgentTypes = ['COMPANY', 'STATE', 'DISTRICT', 'PINCODE'] as const;
export type AgentType = typeof AgentTypes[number];
export interface Agent {
    id: number;
    userId: string;
    mobile: string;
    deliveryMobile: string;
    pincode: string;
    type: AgentType;
    village: string;
    district: string;
    state: string;
}

export interface UserFilters {
    pincode: string;
    isMerchant: string;
    isVerified: string;
    kycStatus: KYCStatus;
}

export interface KYCStatusUpdate {
    userId: string;
    isVerified: boolean;
    comments: string;
    role: string;
}
