import { Location } from "../types/user.types";

export const setLocalStorageJSON = (key: string, value: any): void => {
    let jsonString = "";
    try {
        jsonString = JSON.stringify(value);
    } catch (_) { }
    setLocalStorageItem(key, jsonString);
};

export const setLocalStorageItem = (key: string, value: string): void => {
    localStorage.setItem(`v_${key}`, value);
};

export const getLocalStorageItem = (key: string): string => {
    return localStorage.getItem(`v_${key}`) || null as any;
};

export const getLocalStorageJSON = (key: string): any => {
    let json = getLocalStorageItem(key);
    try {
        json = JSON.parse(json);
    } catch (_) { }
    return json;
};

export const clearLocalStorageToken = (key: string): void => {
    localStorage.removeItem(`v_${key}`);
};

//https://google.com/maps/search/?api=1&query=12.916435,77.6270212
export const getLocation = () => {
    return new Promise<Location>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        },
            () => {
                resolve(null as any);
            });
    });
};

export const reloadPage = () => {
    window.location.reload();
};

export const fixedNumber = (price: number) => +(price || 0).toFixed(2) || 0;

export const formatPrice = (price: number) => (price || 0).toLocaleString('en-IN', { maximumFractionDigits: 2 });

export const formatPriceDecimal = (price: number) => (price || 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getLocationName = (name: string) => (name || '').replace(/\.+|\(+|\)+/g, '').replace(/\s+/g, '-').toLowerCase();

export const isLocalhost = window.location.origin.includes('localhost');

export const openWhatsApp = (mobile: string) => window.open(`https://wa.me/91${mobile}`);

export const openWhatsAppVstep = () => openWhatsApp('8555955026');

export const referOnWhatsApp = (userId: string) => {
    const url = window.location.origin;
    window.open(`https://wa.me/?text=Shop at fastest growing local E-Commerce app Vstep ${url}/signup?referrerId=${userId} and get upto 50% discount on all the products in your area.`);
};

export const call = async (mobile: string) => {
    window.open(`tel:${mobile}`, '_self');
};