import { OrderInit } from "../types/product.types";
import { isDevMode } from "./config.helpers";

export const initPayment = (order: OrderInit) => {
  const errorMessage = 'Error while initiating payment!';
  return new Promise<string>((res, rej) => {
    try {
      let paytmHost = 'https://securegw-stage.paytm.in';
      let marchantId = 'KXIIOE97274592786361';

      if (!isDevMode) {
        paytmHost = 'https://securegw.paytm.in'
        marchantId = 'AEfMOC90463249562469';
      }

      if (!(window as any)?.Paytm) {
        const url = `${paytmHost}/merchantpgpui/checkoutjs/merchants/${marchantId}.js`;
        const script = document.createElement('script');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute('crossorigin', 'anonymous');
        script.setAttribute('src', url);

        document.head.append(script);
      }

      const config = {
        root: "",
        flow: "DEFAULT",
        data: {
          orderId: order.orderId,
          token: order.txToken,
          tokenType: "TXN_TOKEN",
          amount: order.price
        },
        handler: {
          notifyMerchant: function (eventName: any, data: any) {
            if (eventName === 'APP_CLOSED') {
              res('Payment failed please try again!');
            }
          }
        }
      };

      const interval = setInterval(() => {
        if ((window as any)?.Paytm?.CheckoutJS && (window as any).Paytm.CheckoutJS.init) {
          clearInterval(interval);
          (window as any).Paytm.CheckoutJS.init(config).then(function onSuccess() {
            (window as any).Paytm.CheckoutJS.invoke();
          }).catch(function onError(error: any) {
            res(errorMessage);
            console.error("Error in paytm init invoke3: ", error);
          });
        }
      }, 1000);
    } catch (error) {
      res(errorMessage);
      console.error("Error in paytm init: ", error);
    }
  });
};

export const initPaymentCashFree = (order: OrderInit) => {
  const errorMessage = 'Error while initiating payment!';
  return new Promise<string>(async (res, rej) => {
    try {
      if (!(window as any)?.Cashfree) {
        const url = `https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js`;
        const script = document.createElement('script');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute('src', url);
        document.head.append(script);
      }

      const onSuccess = (data: any) => {
        res('');
      };

      const onFailure = (data: any) => {
        res(errorMessage);
      };

      const dropinConfig = {
        orderToken: order.txToken,
        onSuccess,
        onFailure,
        style: {},
        components: ['order-details', 'card', 'netbanking', 'app', 'upi']
      };
      await waitForData(() => (window as any)?.Cashfree);
      const cashfree = new (window as any).Cashfree(order.txToken);
      await waitForData(() => document.getElementById("cashfree-element"));
      cashfree.drop(document.getElementById("cashfree-element"), dropinConfig);
    } catch (error) {
      res(errorMessage);
      console.error("Error in cashfree init: ", error);
    }
  });
};

const waitForData = (getData: () => any) => {
  return new Promise<boolean>((res) => {
    const interval = setInterval(() => {
      if (getData()) {
        clearInterval(interval);
        res(true);
      }
    });
  });
};
