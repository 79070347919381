import { Typography } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { useCallback, useEffect, useState } from "react";
import { Subject } from "rxjs";
import DraggableDialog from "./components/shared/conformation-dialog";
import { useStore } from "./services/store.service";

const firebaseConfig = {
  apiKey: "AIzaSyAp9SaMfDEriv87F9AbkFx87FkKvS1ctjc",
  authDomain: "vstep-push-notifications.firebaseapp.com",
  projectId: "vstep-push-notifications",
  storageBucket: "vstep-push-notifications.appspot.com",
  messagingSenderId: "800226537261",
  appId: "1:800226537261:web:8f05571d9048d22bbbeda4"
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

const requestPermission = async () => {
  console.log('Requesting permission...');
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
  }
};

const onNotification = new Subject<MessagePayload>();

export const registerNotifications = async () => {
  return new Promise<string>(async (res) => {
    await requestPermission();
    getToken(messaging, { vapidKey: "BMtMP2ZEenKRfBGrIX7_QQxPDk_ScQv0ffWS9gq5ah3qahyq2ZBMLaXxKwHR1KLP0jlTuKOY4yWS_2silpLMMbw" })
      .then(async (currentToken) => {
        if (currentToken) {
          console.log('Registration token available. currentToken', currentToken);
          res(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });

    onMessage(messaging, (payload) => {
      onNotification.next(payload);
      console.log('Message received. ', payload);
    });
  });
};

export const Notifications = () => {
  const { user, updateNotificationToken } = useStore(state => state.user);
  const showSnackbar = useStore(state => state.snackBar.showSnackbar);
  const [notificationToken, setNotificationToken] = useState<string>('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    onNotification.subscribe((message) => {
      const element = <>
        <Typography variant="body1">{message?.notification?.title}</Typography>
        <Typography variant='caption'>{message?.notification?.body}</Typography>
      </>;
      const onNotificationClick = () => {
        if (message?.data?.link) {
          window.open(message?.data?.link, '_self');
        }
      };
      showSnackbar(element, 'info', onNotificationClick);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerNotificationToken = useCallback(async () => {
    const token = await registerNotifications();
    setNotificationToken(token);
    const notifications = user?.notificationTokens?.web || [];
    if (!notifications.includes(token)) {
      setIsNotificationOpen(true);
    }
  }, [user?.notificationTokens?.web]);

  useEffect(() => {
    if (user) {
      registerNotificationToken();
    }
  }, [user, registerNotificationToken]);

  const cancelNotification = () => {
    setIsNotificationOpen(false);
  };

  const sendNotificationTokenToServer = () => {
    console.log('Pushing notification token to server...');
    setIsNotificationOpen(false);
    updateNotificationToken(notificationToken);
  };

  return (
    <>
      <DraggableDialog
        open={isNotificationOpen}
        title='Enable Notifications'
        content='Receive updates on orders, wallet, etc.'
        onCancel={cancelNotification}
        onConfirm={sendNotificationTokenToServer}
      />
    </>
  );
}




