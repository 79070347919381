import { Role } from "./user.types";

const HOME = '/';
const CATEGORY1 = '/:category1';
const CATEGORY2 = '/:category2';
const CATEGORY3 = '/:category3';
const CATEGORY4 = '/:category4';
const ACCOUNT = '/profile';
const PRODUCTS = '/products';
const SUPPORT = '/support';
const POLICIES = '/policies';
const POLICY_ID = '/:policy';
const PRODUCT = '/:product';
const SELL = '/sell';
const SELECT_PRODUCT = '/select';
const CREATE_PRODUCT = '/create';
const CART = '/cart';
const AGENT = '/agent';
const BUSINESS = '/business';
const LANDING = '/home';
const WALLET = '/wallet';
const Marchant = '/marchant';
const STORE = '/store';
const STORE_ID = '/:store';
const ORDER_ID = '/:orderId';
const USER_KYC = '/user-kyc';

export const POLICY_ROUTES = {
    TnC: 'tnc',
    PRIVACY: 'privacy',
    MARKET_PLACE: 'market-place',
    PROHIBITED_PRODUCTS: 'prohibited-products',
    CANCEL_RETURN: 'cancel-return'
};
export const ROUTES = {
    login: {
        route: '/login',
        location: '/login',
    },
    signup: {
        route: '/signup',
        location: '/signup',
    },
    forgotPassword: {
        route: '/forgot-password',
        location: '/forgot-password',
    },
    indexHTML: {
        route: '/index.html',
    },
    home: {
        route: HOME,
        location: HOME,
        subRoutes: {
            landing: {
                route: LANDING,
                location: LANDING
            },
            shopping: {
                route: HOME,
                location: HOME,
                subRoutes: {
                    buy: {
                        route: HOME,
                        location: HOME,
                        subRoutes: {
                            product: {
                                route: PRODUCT,
                                location: PRODUCT,
                            }
                        }
                    },
                    rent: {
                        route: '/rent',
                        location: '/rent',
                    },
                }
            },
            products: {
                route: PRODUCTS,
                location: PRODUCTS,
                subRoutes: {
                    products: {
                        route: '/',
                        location: `${PRODUCTS}${CATEGORY1}`,
                    },
                    products1: {
                        route: `${CATEGORY1}`,
                        location: `${PRODUCTS}${CATEGORY1}`,
                    },
                    products2: {
                        route: `${CATEGORY1}${CATEGORY2}`,
                        location: `${PRODUCTS}${CATEGORY1}${CATEGORY2}`,
                    },
                    products3: {
                        route: `${CATEGORY1}${CATEGORY2}${CATEGORY3}`,
                        location: `${PRODUCTS}${CATEGORY1}${CATEGORY2}${CATEGORY3}`,
                    },
                    products4: {
                        route: `${CATEGORY1}${CATEGORY2}${CATEGORY3}${CATEGORY4}`,
                        location: `${PRODUCTS}${CATEGORY1}${CATEGORY2}${CATEGORY3}${CATEGORY4}`,
                    }
                }
            },
            account: {
                route: ACCOUNT,
                location: ACCOUNT,
                subRoutes: {
                    base: {
                        route: '/',
                        location: ACCOUNT,
                    },
                    account: {
                        route: '/account',
                        location: `${ACCOUNT}/account`,
                    },
                    profile: {
                        route: '/my-profile',
                        location: `${ACCOUNT}/my-profile`,
                    },
                    orders: {
                        route: '/orders',
                        location: `${ACCOUNT}/orders`,
                    },
                    dashboard: {
                        route: '/dashboard',
                        location: `${ACCOUNT}/dashboard`,
                    }
                }
            },
            business: {
                route: BUSINESS,
                location: BUSINESS
            },
            wallet: {
                route: WALLET,
                location: WALLET
            },
            marchant: {
                route: Marchant,
                location: Marchant,
                subRoutes: {
                    sell: {
                        route: `${Marchant}${SELL}`,
                        location: `${Marchant}${SELL}`,
                        subRoutes: {
                            selectProduct: {
                                route: `${Marchant}${SELL}${SELECT_PRODUCT}`,
                                location: `${Marchant}${SELL}${SELECT_PRODUCT}`,
                            },
                            createProduct: {
                                route: `${Marchant}${SELL}${CREATE_PRODUCT}`,
                                location: `${Marchant}${SELL}${CREATE_PRODUCT}`,
                            },
                            product: {
                                route: `${Marchant}${SELL}${PRODUCT}`,
                                location: `${Marchant}${SELL}${PRODUCT}`,
                            }
                        }
                    },
                    orders: {
                        route: '/orders',
                        location: `${Marchant}/orders`,
                    },
                    dashboard: {
                        route: '/dashboard',
                        location: `${Marchant}/dashboard`,
                    }
                }
            },
            store: {
                route: STORE,
                location: STORE,
                subRoutes: {
                    sell: {
                        route: `${STORE}${STORE_ID}`,
                        location: `${STORE}${STORE_ID}`,
                        subRoutes: {
                            product: {
                                route: `${STORE}${STORE_ID}${PRODUCT}`,
                                location: `${STORE}${STORE_ID}${PRODUCT}`,
                            }
                        }
                    }
                }
            },
            policies: {
                route: POLICIES,
                location: POLICIES,
                subRoutes: {
                    policy: {
                        route: `${POLICIES}${POLICY_ID}`,
                        location: `${POLICIES}${POLICY_ID}`,
                    },
                    tnc: {
                        route: `${POLICIES}/${POLICY_ROUTES.TnC}`
                    },
                    privacy: {
                        route: `${POLICIES}/${POLICY_ROUTES.PRIVACY}`
                    },
                    marketPlace: {
                        route: `${POLICIES}/${POLICY_ROUTES.MARKET_PLACE}`
                    },
                    prohibitedProducts: {
                        route: `${POLICIES}/${POLICY_ROUTES.PROHIBITED_PRODUCTS}`
                    },
                    cancelReturn: {
                        route: `${POLICIES}/${POLICY_ROUTES.CANCEL_RETURN}`
                    }
                }
            },
            support: {
                route: SUPPORT,
                location: SUPPORT,
            },
            cart: {
                route: CART,
                location: CART,
                subRoutes: {
                    orderStatus: {
                        route: `${CART}${ORDER_ID}`,
                        location: `${CART}${ORDER_ID}`,
                    }
                }
            },
            agent: {
                route: AGENT,
                location: AGENT,
                subRoutes: {
                    userKYC: {
                        route: `${AGENT}${USER_KYC}`,
                        location: `${AGENT}${USER_KYC}`,
                    }
                }
            }
        }
    },
    loginRef: {
        route: '/login',
        location: '/login'
    },
    logoutRef: {
        route: '/logout',
        location: '/logout'
    }
};

type RouteKeys = 'login' |
    'home' | 'products' | 'products1' | 'products2' | 'products3' | 'products4' | 'account' |
    'loginRef' | 'logoutRef';

type Routes = { [key in RouteKeys]?: Route };

interface Route {
    route: string;
    location: string;
    subRoutes?: Routes;
    roles?: Role[];
}
