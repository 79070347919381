import { getFAQsAPI } from '../backend/backend';
import { SupportState } from '../types/store.types';
import { handleAPIError, setSupportState } from './store.service';

export const supportState: SupportState = ({
    faqs: [],
    getFAQs: () => getFAQs()
});

const getFAQs = async () => {
    let isSucceed = false;
    try {
        const response = await getFAQsAPI();
        if (response) {
            isSucceed = true;
            setSupportState({ faqs: response });
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get FAQs Error " });
    } finally {
        return isSucceed;
    }
}
