import { OrderStatus, PaymentStatus } from "../types/product.types";

export const getOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case 'CASH': return 'Cash on delivery';
    case 'DIRECT_QR_TO_CUSTOMER': return 'QR Payment';
    case 'PAY_TO_VSTEP': return 'Payment Initiated';
    case 'PAY_TO_CASHFREE': return 'Payment Initiated';
    case 'PAYMENT_FAILED': return 'Payment Failed';
    case 'PAYMENT_PENDING': return 'Payment Pending';
    case 'ORDERED': return 'Ordered';
    case 'CANCELLED': return 'Cancelled';
    case 'REJECTED': return 'Rejected';
    case 'ACCEPTED': return 'Accepted';
    case 'PACKED': return 'Packed';
    case 'SHIPPED': return 'Shipped';
    case 'OUT_FOR_DELIVERY': return 'Out for delivery';
    case 'DELIVERED': return 'Delivered';
  }
};

export const getPaymentStatus = (status: PaymentStatus) => {
  switch (status) {
    case 'DIRECT_QR_TO_CUSTOMER': return 'QR Payment Validate Transaction ID';
    default: return status;
  }
};

export const getInvoicePaymentStatus = (status: PaymentStatus) => {
  switch (status) {
    case 'DIRECT_QR_TO_CUSTOMER': return 'Paid - QR Payment';
    case 'CASH': return 'Paid - Cash';
    case 'PAY_TO_VSTEP':
    case 'SUCCESS': return 'Paid - Online';
    case 'V_WALLET': return 'Paid - Wallet';
    case 'INITIATED': return 'Not Paid';
    case 'FAILED': return 'Not Paid - Failed';
    case 'PENDING': return 'Pending Confirmation';
    default: return status;
  }
};
