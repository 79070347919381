export const getTimeInMilliseconds = () => new Date().getTime();

export const getTodayTimeInMilliSeconds = function () {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date.setMilliseconds(0);
}();

const year = new Date().getFullYear();
export const getDateString = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  let date;
  try {
    date = new Date(dateString);
  } catch (_) { }
  date = date ? date.toDateString().split(' ') : '';
  return date ? `${date[0]}, ${date[1]} ${date[2]} ${+date[3] < year ? date[3] : ''}` : '';
};

export const getDateInDateStringFormat = (dateString: string) => {
  if (!dateString.endsWith('Z')) {
    dateString = `${dateString}Z`;
  }
  const date = new Date(dateString);
  return `${date.toDateString()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
};