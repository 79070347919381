import { businessTransactionSendToBankAPI, getMyBusinessTransactionsAPI, getMyWalletTransactionsAPI, myWalletBalanceAPI, updateMyBusinessTransactionAPI, updateMyWalletTransactionAPI, walletSendToBankAPI } from '../backend/backend';
import { TransactionTracking, WalletSendToBank, WalletTransaction } from '../components/home/my-wallet/wallet.types';
import { WalletState } from '../types/store.types';
import { handleAPIError, showSnackbar } from './store.service';

export const walletState: WalletState = ({
    getWalletBalance: () => getWalletBalance(),
    getWalletTransactions: () => getWalletTransactions(),
    updateMyWalletTransaction: (id: number, status: TransactionTracking) => updateMyWalletTransaction(id, status),
    walletSendToBank: (transaction: WalletSendToBank) => walletSendToBank(transaction),
    getBusinessTransactions: () => getBusinessTransactions(),
    businessTransactionSendToBank: (transaction: WalletSendToBank) => businessTransactionSendToBank(transaction),
    updateMyBusinessTransaction: (id: number, status: TransactionTracking) => updateMyBusinessTransaction(id, status),
});

const getWalletTransactions = async () => {
    let transactions = [];
    try {
        const response = await getMyWalletTransactionsAPI();
        if (response) {
            transactions = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my wallet transactions error " });
    } finally {
        return transactions;
    }
}

const getWalletBalance = async () => {
    let balance = {} as WalletTransaction;
    try {
        const response = await myWalletBalanceAPI();
        if (response) {
            balance = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my wallet balance error " });
    } finally {
        return balance;
    }
}

const updateMyWalletTransaction = async (id: number, status: TransactionTracking) => {
    let isUpdated = false;
    try {
        const response = await updateMyWalletTransactionAPI(id, status);
        if (response?.status === 200) {
            isUpdated = true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update wallet transaction status error " });
    } finally {
        return isUpdated;
    }
}

const walletSendToBank = async (transaction: WalletSendToBank) => {
    let isSuccess = false;
    try {
        const response = await walletSendToBankAPI(transaction);
        if (response?.status === 200) {
            isSuccess = true;
        }
        showSnackbar(response.message);
    } catch (error: any) {
        handleAPIError({ error, message: "Wallet send money to bank error " });
    } finally {
        return isSuccess;
    }
}

const getBusinessTransactions = async () => {
    let transactions = [];
    try {
        const response = await getMyBusinessTransactionsAPI();
        if (response) {
            transactions = response.data;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Get my business transactions error " });
    } finally {
        return transactions;
    }
}

const updateMyBusinessTransaction = async (id: number, status: TransactionTracking) => {
    let isUpdated = false;
    try {
        const response = await updateMyBusinessTransactionAPI(id, status);
        if (response?.status === 200) {
            isUpdated = true;
        }
    } catch (error: any) {
        handleAPIError({ error, message: "Update business transaction status error " });
    } finally {
        return isUpdated;
    }
}

const businessTransactionSendToBank = async (transaction: WalletSendToBank) => {
    let isSuccess = false;
    try {
        const response = await businessTransactionSendToBankAPI(transaction);
        if (response?.status === 200) {
            isSuccess = true;
        }
        showSnackbar(response.message);
    } catch (error: any) {
        handleAPIError({ error, message: "Business transaction send money to bank error " });
    } finally {
        return isSuccess;
    }
}


