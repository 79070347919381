import axios, { AxiosRequestConfig } from 'axios';
import { getAuthorizationToken } from '../helpers/api.helpers';
import { STATIC_PATH, API_PATH } from '../types/constants';

const axisAPIInstance = axios.create();

axisAPIInstance.interceptors.request.use((config) => {
  return {
    ...config, baseURL: API_PATH, headers: {
      ...config.headers, Authorization: getAuthorizationToken()
    }
  };
});

/**
 * B = Body of the request
 * R = Response of the request
 */
export const postAPI = async <B, R>(url: string, body: B, config: AxiosRequestConfig = {}): Promise<R> => {
  return axisAPIInstance.post(url, body, config).then((resp) => resp.data).catch(err => {
    console.error(err);
    throw Error(err?.response?.data?.message || 'Invalid Request!');
  });
};

export const getAPI = async <R>(url: string, config: AxiosRequestConfig = {}): Promise<R> => {
  return axisAPIInstance.get(url, config).then((resp) => resp.data);
};

export const putAPI = async <B, R>(url: string, body: B, config: AxiosRequestConfig = {}): Promise<R> => {
  return axisAPIInstance.put(url, body, config).then((resp) => resp.data);
};

export const deleteAPI = async <R>(url: string, config: AxiosRequestConfig = {}): Promise<R> => {
  return axisAPIInstance.delete(url, config).then((resp) => resp.data);
};

const axisStaticInstance = axios.create();

axisStaticInstance.interceptors.request.use((config) => {
  return {
    ...config, baseURL: STATIC_PATH, headers: {
      ...config.headers
    }
  };
});

export const getStaticAPI = async <R>(url: string, config: AxiosRequestConfig = {}): Promise<R> => {
  return axisStaticInstance.get(url, config).then((resp) => resp.data);
};
